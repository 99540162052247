import { ADD_ARTICLE } from "../_constants/action-types";

const initialState = {
    articles: []
  };
  
  function rootReducer(state = initialState, action) {
    if (action.type === ADD_ARTICLE) {
      localStorage.setItem('articles', JSON.stringify(state.articles))
      return Object.assign({}, state, {
        articles: state.articles.concat(action.payload)        
      });
    }
    return state;
  };
  
  export default rootReducer;