import React, { Component } from 'react';
import { Connector } from '../_api/connector';

export class About extends Component {
    static displayName = About.name;
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        const columnStyle = {
            columns: 2,
            'marginLeft': '15px',
        };
        Connector.resetToken();
        return (
            <div className="row m-3 rounded border shadow">
                <div className="col">                 
                        <h1 className="mt-2">Biblical Hebrew Learning Tool</h1>

                        <p>The Biblical Hebrew Learning Tool is designed for beginning and intermediate students of Biblical Hebrew. The Learning Tool features five components:
                            Vocabulary, Pronunciation, Verb Parsing, Grammar, and Translation. </p>

                        <p><strong>Vocabulary:</strong> This component presents the 1,000 most frequent words in the Hebrew Bible, listed according to their frequency. 
                        Users can select and study vocabulary words, shuffle the list, listen to the pronunciation (either a male or female voice), and show the translation.
                        </p>

                        <p><strong>Pronunciation: </strong> This component allows users to practice pronouncing (1) the Hebrew alphabet; (2) the most frequent 1,000 Hebrew words; and (3) 27 selected literary units (about 500 verses) 
                            from the Hebrew Bible. Users see the letter, word, or phrase as they listen to a female or male voice (modern Israeli pronunciation) enunciate the selected study unit. 
                        </p>

                        <p><strong>Verb Parsing:  </strong> This component comprises approximately 3,000 verbs, selected directly from the Hebrew Bible, 
                        for users to parse. Users may select any or all of the following forms—Qal, Niphal, Piel, Pual, Hiphil, Hophal, and Hithpael as well as any or all of 
                        the following tenses—perfect, imperfect, participle, infinitive, and imperative. The “Explanation” button provides verb parsing indicators, so that 
                        users can improve their parsing skills.
                        </p>

                    <p><strong>Grammar:  </strong> This component contains over 2,000 phrases derived from the Hebrew Bible
                        that pertain to the following grammatical concepts: </p>

                        <ul style={columnStyle}>
                            <li>Construct forms with and without definite article</li>
                            <li>Definite article with attached preposition</li>
                            <li>Adjective and Noun Combinations</li>
                            <li>The Object Marker</li>
                            <li>Preposition Mem + Comparative Mem</li>
                            <li>Demonstrative Adjectives and Pronouns</li>
                            <li>Narrative Sequences</li>
                            <li>Pronominal Suffixes on Prepositions</li>
                            <li>Pronominal Suffixes on Nouns</li>
                            <li>Object Suffixes on Verbs</li>                           
                        </ul>

                    
                    <p>The grammar phrases used in this component are sorted both by the concepts mentioned above as well as vocabulary frequency. Users may type their translation into a window and then click the “Translate” button to compare their response with the correct translation.</p>

                    <p><strong>Translation:  </strong> This component provides translation exercises from 28 different literary units (about 500 verses), 
                        including examples from historical narrative, poetry, or legal verses found in the Hebrew Bible: </p>

                        <ul style={columnStyle}>
                            <li>The Creation (Gen. 1:1-31)</li>
                            <li>Adam and Eve (Gen. 3:1-24)</li>
                            <li>Noah and the Ark (Gen. 6:1-22)</li>
                            <li>Sarah Bears Isaac (Gen. 21:1-8)</li>
                            <li>Jacob Meets Rachel at the Well (Gen. 29:1-14)</li>
                            <li>Joseph of Egypt (Gen. 37:1-36)</li>
                            <li>Moses and the Burning Bush (Exod. 3:1-22)</li>
                            <li>The Passover (Exod. 12:1-28)</li>
                            <li>Manna, Bread from Heaven (Exod. 16:11-35)</li>
                            <li>Moses Smites the Rock (Exod. 17:1-7)</li>
                            <li>The Ten Commandments (Exod. 20:1-17)</li>
                            <li>Joshua Sends Spies to Jericho (Josh. 2:1-24)</li>
                            <li>Deborah, the Prophetess (Judges 4:1-24)</li>
                            <li>Gideon and His Army (Judges 7:1-23)</li>
                            <li>Samson and His Wife (Judges 14:1-20)</li>
                            <li>Naomi and Ruth (Ruth 1:1-19)</li>
                            <li>Hannah and Samuel (1 Sam. 1:1-23)</li>
                            <li>The Lord Calls Samuel (1 Sam. 3:1-10)</li>
                            <li>David and Goliath (1 Sam. 17:32-54)</li>
                            <li>Elijah and the Chariot of Fire (2 Kings 2:1-11)</li>
                            <li>Elisha Multiplies the Widow's Oil (2 Kings 4:1-17)</li>
                            <li>Esther Is Chosen Queen (Esther 2:1-17)</li>
                            <li>The Lord Is My Shepherd (Ps. 23:1-6)</li>
                            <li>Who Shall Ascend into the Lord's Mountain (Ps. 24:1-10)</li>
                            <li>The Lord Sitting upon the Throne (Isa. 6:1-8)</li>
                            <li>Hezekiah's Life Is Lengthened (Isa. 38:1-8)</li>
                            <li>Hosea and His Family (Hosea 1:1-9)</li>
                            <li>Jonah and the Fish (Jonah 1:1-4, 10-17)</li>                        
                        </ul>

                    
                    <p>Users may type their translation into a window and then click the “Translate” button to compare their own translation with either the King James Version or the New International Version translations. 
                    </p>                                      
                </div>
            </div>
        );
    }
}