import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { config } from "../Config.custom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddressForm from "./AddressForm";
import AccountForm from "./AccountForm";
import Review from "./Review";
import { Formik, Form } from "formik";
import validationSchema from "./FormModel/validationSchema";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import formInitialValues from "./FormModel/formInitialValues";
import { staticList } from "../../_services/staticList";
import { sessionTools } from "../../_services/sessionTools"

import { creativeworksStore } from "../../_services/creativeworksStore";

import ValidationMessage from "../ValidationMessage";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["Account", "Billing information", "Choose Subscription"];
const { formId, formField } = checkoutFormModel;

function _renderStepContent(step, casUser, captchaResponse) {
  switch (step) {
      case 0:
          return <AccountForm formField={formField} casUser={casUser} />;
    case 1:
      return <AddressForm formField={formField} />;
     case 2:
          return <Review formField={formField} casUser={casUser} setCaptchaVerified={captchaResponse}/>;
    default:
      return <div>Not Found</div>;
  }
}

export default function Checkout({ casUser }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const [isSubmitting, setisSubmitting] = React.useState(false);
    const [goToCashNet, setGoToCashNet] = React.useState(false);
    const [captchaVerified, setCaptchaVerified] = React.useState(false);
    const [showWarningWrong, setshowWarningWrong] = React.useState(false);
    const [showWarningCaptcha, setshowWarningCaptcha] = React.useState(false);
  const [formField, setformField] = React.useState("");
  const [cashnetConfiguration, setCashNetConfiguration] = React.useState([]);
  const [AccountGLCode, setGLCode] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");

  useEffect(() => {
    getCashNetConfiguration();
    staticList.getCountry();
    staticList.getStates();
  }, []);

    
    const getCashNetConfiguration = () => {
        var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
    fetch(
      config.api_cwo +
        `CashNetResponse/GetConfiguration?itemName=BiblicalSubscription&casUser=`+ casUser.toString(),
      {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: bearer
        },
      }
    )
      .then((res) => res.json())
      .then(
          (result) => {
          if (result.result === "0") {
            setCashNetConfiguration(result);
          } else {
            setCashNetConfiguration(null);
          }
        },
        (error) => {
          console.log(error);
          return [];
        }
      )
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  const _handleSubmit = (values, actions) => {
    if (isLastStep) {
        setisSubmitting(true);
        if (captchaVerified) {
            creativeworksStore.saveOrder(
                values,
                casUser,
                setGoToCashNet,
                setshowWarningWrong,
                setisSubmitting
            );
        }
        else {
            setisSubmitting(false);
            setshowWarningCaptcha(true);
        }
      setformField(values);
    } else {
        setActiveStep(activeStep + 1);
        actions.setTouched([]);
        actions.setSubmitting(false);
        if (AccountGLCode === '' && values.country !== '' && values.state !== '') {
            //console.log('setGLCode')
            creativeworksStore.getGLCode(values.country, values.state, casUser, setGLCode);
        };
        if (customerName === '' && values.firstName !== '' && values.lastName !== '') {
            creativeworksStore.getCustomerName(values.firstName, values.lastName, setCustomerName);
        }
    }
  }

    const handleBack = () => {
        setCaptchaVerified(false);
    setActiveStep(activeStep - 1);
  };

  const CashNetForm = ({ formField }) => {
    const formRef = useRef(null);
      useEffect(() => {
        formRef.current.submit();
    }, []);
    let itemDescription = "";
    let itemPrice = "";
    var subs = JSON.parse(sessionTools.getCookie("Subscription"));
    let byucas = 0;
      if (casUser) {
          byucas = 1;
      }
    subs.map(function (elm) {
        if (elm["Months"] === formField.subscription) {
            if (elm["Student"] === byucas) {
                itemDescription = elm["Name"];
                itemPrice = elm["Price"];
            }
        }
        return true;
    });
          return (
              <React.Fragment>
                  <CssBaseline />
                  <main className={classes.layout}>
                      <form
                          ref={formRef}
                          id="CashNetForm"
                          method="post"
                          action={cashnetConfiguration.actionURLTraining} //This needs to change when you are publishing to training/production
                          name="CASHNetForm"
                      >
                          <input type="hidden" name="NAME_G" value={customerName} />
                          <input type="hidden" name="custcode" value={formField.guid} />
                          <input type="hidden" name="addr" value={formField.address1} />
                          <input type="hidden" name="city" value={formField.city} />
                          <input type="hidden" name="state" value={formField.state} />
                          <input type="hidden" name="zip" value={formField.zip} />
                          <input type="hidden" name="country" value={"United States"} />
                          <input type="hidden" name="email" value={formField.email} />
                          <input
                              type="hidden"
                              name="itemcode1"
                              value={cashnetConfiguration.cashNetItemCode}
                          />
                          <input type="hidden" name="amount1" value={itemPrice} />
                          <input type="hidden" name="qty1" value="1" />
                          <input type="hidden" name="desc1" value={itemDescription} />
                          <input type="hidden" name="ref1type1" value="GL" />
                          <input type="hidden" name="ref1val1" value={AccountGLCode} />
                          <input type="hidden" name="itemcnt" value="1" />
                          <input type="hidden" name="casuser" value={casUser} />
                          <input
                              type="hidden"
                              name="signouturl"
                              value={cashnetConfiguration.signouturl}
                          />
                          <input
                              type="hidden"
                              name="incompletesignouturl"
                              value={cashnetConfiguration.incompletesignouturl}
                          />
                      </form>
                  </main>
              </React.Fragment>
          );
  };
  return (
    <React.Fragment>
      <CssBaseline />

      <ValidationMessage
        open={showWarningWrong}
        setshowWarningWrong={setshowWarningWrong}
        message="Oops, something went wrong. Please, try again."
        severity="warning"
          />
          <ValidationMessage
              open={showWarningCaptcha}
              setshowWarningWrong={setshowWarningCaptcha}
              message="Please verify through reCaptcha."
              severity="warning"
          />

      <main className={classes.layout}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          <Backdrop className={classes.backdrop} open={isSubmitting}>
            <CircularProgress color="inherit" />
          </Backdrop>

          {goToCashNet ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Opening payment platform
              </Typography>
              <Typography variant="subtitle1">
                <CashNetForm formField={formField} />
              </Typography>
            </React.Fragment>
          ) : (
            <Formik
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form id={formId}>
                  {_renderStepContent(activeStep, casUser, setCaptchaVerified)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <div className={classes.wrapper}>
                      <Button
                        disabled={showWarningWrong}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {isLastStep ? "Place order" : "Next"}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </React.Fragment>
      </main>
    </React.Fragment>
  );
}
