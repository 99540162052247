import { config } from '../components/Config.custom';
import { sessionTools } from './sessionTools';

export const staticList = {
  getCountry,
  getStates,
  getVocabulary_VocabularyStudyList,
  getVocabulary_VocabularySets,
  getBiblical_UnitSets,
  getBiblical_AlphabetSets
};

async function getCountry() {
    var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
  const requestOptions = {
    method: 'POST',
      headers: {
        'Authorization': bearer,
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
        
      },
  };
  fetch(config.api_cwo + `Country`, requestOptions)
    .then(res => (res.json()))
    .then(
        (result) => {
        localStorage.setItem('countries', JSON.stringify(result));
      }
    )
    .catch(function (error) {
    });
}

async function getStates() {
  var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
  const requestOptions = {
    method: 'POST',
    headers: {
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': bearer
      }
  };
  fetch(config.api_cwo + `State`, requestOptions)
    .then(res => (res.json()))
    .then(
        (result) => {
        localStorage.setItem('states', JSON.stringify(result));
      }
    )
    .catch(function (error) {
    });
}

async function getVocabulary_VocabularyStudyList() {
  try {
    fetch(config.api_url + 'VocabularySets')
      .then(res => res.json())
      .then(
        (result) => {
          localStorage.setItem('hebrew_VocabularyStudyList', JSON.stringify(result));          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
        }
      )
  } catch (e) {
    console.info(e);
  }
}

async function getVocabulary_VocabularySets() {
  try {
    fetch(config.api_url + 'VocabularyWords')
      .then(res => res.json())
      .then(
        (result) => {
          localStorage.setItem('hebrew_VocabularySets', JSON.stringify(result));          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
        }
      )
  } catch (e) {
    console.info(e);
  }
}

async function getBiblical_AlphabetSets() {
  try {
    fetch(config.api_url + 'AlphabetSets')
      .then(res => res.json())
      .then(
        (result) => {
          localStorage.setItem('hebrew_AlphabetSets', JSON.stringify(result));          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
        }
      )
  } catch (e) {
    console.info(e);
  }
}

async function getBiblical_UnitSets() {
  try {
    fetch(config.api_url + 'TranslationSets')
      .then(res => res.json())
      .then(
        (result) => {
          localStorage.setItem('hebrew_BiblicalUnitSets', JSON.stringify(result));          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
        }
      )
  } catch (e) {
    console.info(e);
  }
}