import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputField from "../SignUp/FormModel/inputField";
import SelectFieldAPI from "../SignUp/FormModel/SelectFieldAPI";
import { Connector } from "../../_api/connector.js"
import { keys } from "@material-ui/core/styles/createBreakpoints";

export default function AddressForm(props) {
  const [states, setStates] = React.useState(false);
  const {
    formField: {
      firstName,
      lastName,
      address1,
      address2,
      city,
      state,
      zipcode,
      country,
    },
  } = props;
    const handleChangeCountry = (value) => {
        if (value === 1) {
            setStates(true);
        }
        else setStates(false);
    };
    const handleChangeState = (value) => {
        //document.getElementsByName('state')[0].value = '';
        //console.log(document.getElementsByName("state"));
    };
    Connector.resetToken();
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Billing Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField name={firstName.name} label={firstName.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={lastName.name} label={lastName.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={address1.name} label={address1.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={address2.name} label={address2.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectFieldAPI
                name={country.name}
                label={country.label}
                myChange={handleChangeCountry}
                disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
                  <SelectFieldAPI
                      name={state.name}
                      label={state.label}
                      myChange={handleChangeState}
                      disabled={states}
                  />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={city.name} label={city.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={zipcode.name} label={zipcode.label} fullWidth />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
