import * as Yup from 'yup';
//import moment from 'moment';
import checkoutFormModel from './checkoutFormModel';
import { UserService } from '../../../_services/userService'

const {
  formField: {
    email,
    username,
    password,
    passwordRepeat,
    firstName,
    lastName,
    address1,
    city,
    state,
    country,
    zipcode,
    subscription
  }
} = checkoutFormModel;

const visaRegEx = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;

var validationArray = [
    //step1
    Yup.object().shape({
        [username.name]: Yup.string()
            .required(`${username.requiredErrorMsg}`)
            .test("ValidateExist", `${username.requiredExistErrorMsg}`, function (value) { // Use function
                return UserService.usernameValidation(value)
                    .then(function (uid) {
                        return (uid)
                    })
            })
            .test(
                'len',
                `${username.requiredLengthErrorMsg}`,
                val => val && val.length >= 6
            ),
        [email.name]: Yup.string()
            .required(`${email.requiredErrorMsg}`)
            .matches(visaRegEx, email.requiredValidErrorMsg)
            .test("ValidateExist", `${email.requiredExistErrorMsg}`, function (value) { // Use function
                return UserService.emailValidation(value)
                    .then(function (uid) {
                        return (uid)
                    })
            })
            .test(
                'len',
                `${username.requiredLengthErrorMsg}`,
                val => val && val.length >= 6
            ),
        [password.name]: Yup.string()
            .required(`${password.requiredErrorMsg}`)
            .test(
                'len',
                `${password.requiredLengthErrorMsg}`,
                val => val && val.length >= 6
            ),
        [passwordRepeat.name]: Yup.string()
            .nullable()
            .required(`${passwordRepeat.requiredErrorMsg}`)
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    }),
    //step2
    Yup.object().shape({
        [firstName.name]: Yup.string().required(`${username.requiredErrorMsg}`),
        [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
        [zipcode.name]: Yup.string()
            .required(`${zipcode.requiredErrorMsg}`)
            .test(
                'len',
                `${zipcode.invalidErrorMsg}`,
                val => val && val.length >= 5
            ),
        [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
        [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
        [state.name]: Yup.string().required(`${state.requiredErrorMsg}`)
            .test(
                'US State',
                `${state.invalidStateMsg}`,
                (val, ctx) => {
                    var passedTest = false
                    if (ctx.parent.country == 1) {
                        JSON.parse(localStorage.getItem('states'))['states'].forEach(element => {
                            if (val == element.name) {
                                passedTest = true
                            }
                        })
                    }
                    else { passedTest = true }
                    return passedTest
              }),
    [country.name]: Yup.string().required(`${country.requiredErrorMsg}`)
  }),
  // step3
  Yup.object().shape({  
    [subscription.name]: Yup.string().required(`${subscription.requiredErrorMsg}`)
  }),
];

export default validationArray;