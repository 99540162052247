// Constants.js
const prod = {
    api_url: 'https://creativeworks.byu.edu/apiBiblicalHebrew/',
    api_cwo: 'https://creativeworks.byu.edu/api.CreativeWorks/',
    domain: 'https://creativeworks.byu.edu/BiblicalHebrew/',
    captcha_site: '6LfQ374ZAAAAAIABKCNqeng8MHjbcrQTfwbBJXBZ',
    captcha_secret: '6LfQ374ZAAAAALGsVUfu7wumeM3FFwgC8ZehmS3w'
};
//api_cwo: 'http://54.71.38.62/apiCreativeWorks/',
//api_cwo: 'http://train.creativeworks.byu.edu/apiCreativeWorks/',

//const dev = {
//    api_url: 'http://localhost/apiBiblicalHebrew/' ,
//    api_cwo:  'http://localhost/apiCreativeWorks/'  
//};
export const config = process.env.NODE_ENV === 'development' ? prod : prod;