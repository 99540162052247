import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Checkout from './Checkout';
import Link from '@material-ui/core/Link';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);




 export default function SignUp( { homepage } ) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //Returns signup link as a button if for the homepage, but as an account link looking thing if not 
  if (homepage) {
    return (
      <div>
        <button className="btn btn-secondary" onClick={handleClickOpen}>Sign Up</button>   
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={false} maxWidth={"md"}
        disableBackdropClick
        disableEscapeKeyDown >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Sign Up 
          </DialogTitle>
          <DialogContent dividers>
            <Checkout casUser={false} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  else {
    return (
      <div>
       <Link onClick={handleClickOpen} variant="body2" style={{cursor:'pointer'}}>Don't have an account? Sign Up</Link> 
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={false} maxWidth={"md"}
        disableBackdropClick
        disableEscapeKeyDown >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Sign Up 
          </DialogTitle>
          <DialogContent dividers>
                    <Checkout casUser={false} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
