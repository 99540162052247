import React, { Component } from 'react';
import Checkout from './SignUp/Checkout';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CasClient from "react-cas-client";
import { Redirect } from 'react-router-dom'




export class CASSignUp extends Component {
    static displayName = CASSignUp.name;
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            successfulCAS: false
        };
    }

    render() {
        let casEndpoint = 'cas.byu.edu';
        let casOptions = {
            'protocol': 'http'
        };
        let gateway = false;
        let successfulCAS = this.state;

        let casClient = new CasClient(casEndpoint, casOptions);
        casClient
            .auth(gateway)
            .then(successRes => {
                successfulCAS = true;

            })
            .catch(errorRes => {
                console.error(errorRes);
            })
        if (successfulCAS) {
            return (
                <div className="row m-3 rounded border shadow">
                    <div className="col">
                        <AppBar position="absolute" color="default">
                            <Toolbar>
                                <Typography variant="h6" color="inherit" noWrap>
                                    Student Sign-Up
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Checkout casUser={true}/>
                        <br></br>
                    </div>
                </div>
            )
        }
        else { return <Redirect to='/Home' /> }



    }

}