import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import './FlippingCardt.scss';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import { Connector } from '../_api/connector';




// React component for the front side of the card
export class CardFront extends React.Component {
    constructor() {
        super();
        this.state = {
            front: true
        };

        this.showExplanationIconClick = this.showExplanationIconClick.bind(this);
        this.shuffleListClick = this.shuffleListClick.bind(this);
        this.verbShuffle = this.verbShuffle.bind(this);
    }
    FlipCard() {
        this.setState({ front: !this.state.front })
    }

    //Calls function in Card class component to set state that is held there
    showExplanationIconClick(e) {
        this.props.showExplanationIconClick();
    }

    shuffleListClick() {
        this.props.shuffleListClick();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):

    }

    verbShuffle(){
        if(this.props.termtext !== 'No available cards for current settings'){
            this.props.onVerbShuffle();
            this.props.SetCurrentCard(0);
            if (this.props.translationBox === true){
                this.translationValue.getElementsByClassName("MuiInputBase-input")[0].value="";
            }
        }
    }
    render() {

        Connector.resetToken();

        let styletermtext = null;
        if (this.props.fontSize) {
            styletermtext = { fontSize: this.props.fontSize }
        }
       
        //let showTranslationButton = null;
        if (this.props.flipCard) {
            //showTranslationButton = (<Button variant="outlined" color="primary">Show Translation</Button>);
        }

        let explanationIcon = null;
        if (this.props.displayExplanationIcon) {
            explanationIcon = (
                <Tooltip title={this.props.explanationIconSubText}>
                <Button onClick={this.showExplanationIconClick}
                    variant="outlined"
                    color="primary"
                    size="large"
                    // startIcon={<SpeakerNotesIcon />}
                    > 
                    {this.props.explanationIconText}
                </Button>
                </Tooltip>);
        }


        //Shuffle button
        let ShuffleButton = null;
        if (this.props.verbParsingShuffle) {
            ShuffleButton = (<div className="displayShuffleButton">
            <Tooltip title="Shuffle List">
            <Button onClick={this.verbShuffle}
                variant="outlined"
                color="primary"
                size="large"
                // startIcon={<ShuffleIcon />}
                > 
                Shuffle
            </Button>
            </Tooltip></div>);
        }
        else if (this.props.displayShuffleButton) {
            ShuffleButton = (<div className="displayShuffleButton">
            <Tooltip title="Shuffle List">
            <Button onClick={this.shuffleListClick}
                variant="outlined"
                color="primary"
                size="large"
                // startIcon={<ShuffleIcon />}
                > 
                Shuffle
            </Button>
            </Tooltip></div>);
        }
        let termexplanation = null;
        if (this.props.showExplanation) {
            if (this.props.termexplanation.length <= 300){
                termexplanation = (<div className="termexplanation">
                    {this.props.termexplanation}
                </div>);
            }
            else {
                termexplanation = (<div className="termexplanationsmall">
                    {this.props.termexplanation}
                </div>);
            }
        }
        return (
            <div className='card-side side-front'>
                <div className="biblicalReference">{this.props.reference}</div>
                <div className='container-fluid'>
                    <div className='side-front-content'>
                        
                        <div className='flashcard'>
                            <div className="termtext" style={styletermtext} dir="rtl">
                                {this.props.termtext}
                            </div>
                            <div className="explanationtext">
                            {this.props.showExplanation ? termexplanation : null}
                            </div>
                        <div className="row justify-space-between align-content-center">
                            <div className="col-2 cardButtonLeft">
                                {explanationIcon}
                            </div>
                            <div className="col-8 cardCount">
                                <div>{ this.props.currentCard} / {this.props.maxItems}</div>
                            </div>
                            <div className="col-2 cardButtonRight">
                                {ShuffleButton}
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        )
    }
}

// React component for the back side of the card
// Used for flipping the card (removed by request from Parry)
// export class CardBack extends React.Component {
//     constructor() {
//         super();
//         this.state = {

//         }
//     }
//     render() {
//         return (
//             <div className='card-side side-back'>
//                 <div className='container-fluid'>
//                     <div className='row'>
//                         <div className='m-4 col side-front-content'>
//                             <div className="termdefinition">
//                                 {this.props.termdefinition}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }

export function RadioButtonsGroup(props) {
    const [value, setValue] = React.useState('female');
    const handleChange = (event) => {
        setValue(event.target.value);
        props.onChildClick(event.target.value);
    };
    return (
        <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                <FormControlLabel value="female" control={<Radio color="primary" />} label="Female" />
                <FormControlLabel value="male" control={<Radio color="primary" />} label="Male" />
            </RadioGroup>
        </FormControl>
    );
}

export class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.AudioPlayer = React.createRef();
        this.AudioReload = this.AudioReload.bind(this);
        this.play = this.play.bind(this);
        this.state = {
            styleIcon: 'fas fa-volume-up fa-3x iconPlay',
            extensionFile: '.mp3'
        }
    }
    async componentDidMount() {
        if (this.AudioPlayer.current.canPlayType('audio/mpeg') === 'probably') {
            this.setState({ extensionFile: '.mp3' }); 
        } else {
            this.setState({ extensionFile: '.mp3' });
        }        
    }
    componentWillReceiveProps(nextProps) {
        // When the url changes, we refresh the component manually so it reloads the loaded file
        if ((nextProps.src) && (nextProps.src !== this.props.src)) {
            // Change the source
            this.AudioPlayer.current.src = nextProps.src + this.state.extensionFile;
            // Cause the audio element to load the new source            
            this.AudioPlayer.current.load();
            //this.AudioPlayer.current.play();
        }
    } 
    play() {
        this.setState({
            styleIcon: 'fas fa-volume-up fa-3x iconPlayPlaying'
        }, () => {
            this.AudioPlayer.current.play();
        });
    }
    AudioReload() {
        this.setState({
            styleIcon: 'fas fa-volume-up fa-3x iconPlay'
        }, () => {
            this.AudioPlayer.current.pause();
            this.AudioPlayer.current.load();
            //this.AudioPlayer.current.play();
        });
    }   
    render() {
        let styleHide = { display: 'none' }
        return (
            <div className='mt-3'>
                <i style={styleHide} onClick={this.play} className={this.state.styleIcon} ></i>
                <div >
                    <audio controls ref={this.AudioPlayer} src={this.props.src+this.state.extensionFile} onEnded={this.AudioReload} >
                        <source src={this.props.src+this.state.extensionFile} />
                    </audio>
                </div>
            </div>
        );
    }
}
function arraysEqual(a1, a2) {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1) === JSON.stringify(a2);
}
// React component for the card (main component)
export class Card extends React.Component {
    constructor(props) {
        super(props);
        this.audioRef = React.createRef();
        this.handlerGenderChange = this.handlerGenderChange.bind(this);
        this.handleClick_back = this.handleClick_back.bind(this);
        this.handleClick_foward = this.handleClick_foward.bind(this);
        this.getAudioSrc = this.getAudioSrc.bind(this);
        this.SetCurrentCard = this.SetCurrentCard.bind(this);
        this.ShuffleList = this.ShuffleList.bind(this);
        this.showExplanationIconClick = this.showExplanationIconClick.bind(this);
        //this.handleMyTranslationChange = this.handleMyTranslationChange.bind(this);
        this.state = {
            genderAudio: 'female',
            audiosrc_ogg: '',
            currentCard: 0,
            text: '',
            definition: '',
            maxIndex: 0,
            reference: '',
            flipCard: true, //by default
            styleContainter: 'card-container', // no flip : card-container-no-Flip
            currentWordId: 0,
            displayExplanationText: false
        };
    }
    async componentDidMount() {
        //this.state.text = this.props.defaultText;
        //this.state.definition = this.props.defaultDefinition;
        //this.state.wordsList = this.props.wordslist;
        if (this.props.flipCard === true) {
            this.setState({
                flipCard: this.props.flipCard,
                styleContainter: 'card-container'
            });
        } else {
            this.setState({
                flipCard: this.props.flipCard,
                styleContainter: 'card-container-no-Flip'
            });
        }
    }
    componentDidUpdate(prevProps) {

        // Typical usage (don't forget to compare props):
        if (this.props.textPropertyName !== prevProps.textPropertyName
            && prevProps.textPropertyName !== '') {
            if (this.props.wordsList) {
                this.setState({
                    text: this.props.wordsList[this.state.currentCard][this.props.textPropertyName]
                });
            }
        }
        if (this.props.flipCard !== prevProps.flipCard) {
            if (this.props.flipCard === true) {
                this.setState({
                    flipCard: this.props.flipCard,
                    styleContainter: 'card-container'
                });
            } else {
                this.setState({
                    flipCard: this.props.flipCard,
                    styleContainter: 'card-container-no-Flip'
                });
            }
        }
        if (this.props.defaultText !== prevProps.defaultText) {
            this.SetCurrentCard(0);
            if (this.props.translationBox === true){
                this.translationValue.getElementsByClassName("MuiInputBase-input")[0].value="";
            }
            this.setState({
                text: this.props.defaultText,
                currentCard: 0
            }, () => {
                // this.audioChangeClick(this.state.genderAudio);
            });
        }
        if (this.props.defaultDefinition !== prevProps.defaultDefinition) {
            this.setState({
                definition: this.props.defaultDefinition,
                reference: ''
            });
        }
        if (this.props.definitionPropertyName !== prevProps.definitionPropertyName) {
            this.setState({
                definition: this.props.wordsList[this.state.currentCard][this.props.definitionPropertyName]                
            });
        }
        if (!arraysEqual(this.props.wordsList, prevProps.wordsList)) {   
            if(this.props.wordsList.length !== 0) {  
                this.SetCurrentCard(0);
                if (this.props.translationBox === true){
                    this.translationValue.getElementsByClassName("MuiInputBase-input")[0].value="";
                }
                this.setState({
                    maxIndex: this.props.wordsList.length - 1,
                    currentCard: 0,
                    currentWordId: this.props.wordsList[0][this.props.wordIdPropertyName],
                }, () => {
                    if (this.props.wordsList[0].reference) {
                        this.setState({ reference: this.props.wordsList[0].reference });
                    }
                });
            }
            else {
                this.setState({
                    maxIndex: 0
                });
            }     
        }
    }

    getAudioSrc() {
        var _newAudio = null;      
        if (this.props.wordIdPropertyName) {
            _newAudio = this.props.pathaudioFile + this.props.groupName + ("0" + (this.state.currentWordId)).slice(-2) + this.state.genderAudio.substring(0, 1);
        } else {
            _newAudio = this.props.pathaudioFile + this.props.groupName + ("0" + (this.state.currentCard + 1)).slice(-2) + this.state.genderAudio.substring(0, 1);
        }        
        return _newAudio;
    }
    handlerGenderChange(optionValue) {
        this.setState({ genderAudio: optionValue });
        this.setState ({ explanationIcon: false})
    }
    handleClick_back() {
        if (this.props.wordsList.length !== 0) {
            var nextIndex = this.state.currentCard;
            this.setState ({ displayExplanationText: false})
            if (this.props.translationBox === true){
                this.translationValue.getElementsByClassName("MuiInputBase-input")[0].value="";
            }
            if (this.state.currentCard > 0) {
                nextIndex = (this.state.currentCard - 1);
                this.SetCurrentCard(nextIndex);
            } else {
                this.SetCurrentCard(this.state.maxIndex);
            }
        }
    }
    handleClick_foward() {
        if (this.props.wordsList.length !== 0){
            var nextIndex = this.state.currentCard;
            this.setState ({ displayExplanationText: false})
            if (this.props.translationBox === true){
                this.translationValue.getElementsByClassName("MuiInputBase-input")[0].value="";
            }
            if (nextIndex < this.state.maxIndex) {
                nextIndex = (this.state.currentCard + 1);
                this.SetCurrentCard(nextIndex);
            } else {
                this.SetCurrentCard(0);
            }
        }
    }
    SetCurrentCard(nextIndex) {
        try {
            this.setState({
                text: this.props.wordsList[nextIndex][this.props.textPropertyName],
                definition: this.props.wordsList[nextIndex][this.props.definitionPropertyName],
                reference: this.props.wordsList[nextIndex].reference,
                currentCard: nextIndex,
                currentWordId: this.props.wordsList[nextIndex][this.props.wordIdPropertyName],
                showExplanation: false,
                displayShuffleButton: true,
            }, () => {
                if (this.props.onSetCurrentCard) {
                    this.props.onSetCurrentCard(nextIndex);
                }
            });
        } catch (e) {
            //console.log('catch')
            this.setState({
                text: 'No available cards for current settings',
                definition: '',
                reference: '',
                currentCard: nextIndex,
                currentWordId: '',
                showExplanation: false,
                displayShuffleButton: false
            })
        }
    }
    //Shuffle List Function
    ShuffleList() {
        if (this.props.wordsList.length !== 0) {
            var array = this.props.wordsList
            var i = array.length,
            j = 0,
            temp;
            while (i--) {
            j = Math.floor(Math.random() * (i + 1));

            temp = array[i];
            array[i] = array[j];
            array[j] = temp;
            }
            this.setState({
            wordsList: array, 
            })
            this.SetCurrentCard(0);
            if (this.props.translationBox === true){
                this.translationValue.getElementsByClassName("MuiInputBase-input")[0].value="";
            }
        }
    }

    //Handles changes to the translation (as they type function) removed by request
    // handleMyTranslationChange = event => {
    //     if (event.target.value.length >= 2) {
    //         if (event.target.value.toLowerCase() === this.state.definition.toLowerCase()) {
    //             this.setState({ translationError: false, helperText: 'Good job!' });
    //         } else {
    //             this.setState({ translationError: true, helperText: 'Keep trying!' });
    //         }
    //     } else {
    //         this.setState({ translationError: false, helperText: '' });
    //     }
    //     this.setState({ myTranslation: event.target.value });
    // };

    showExplanationIconClick(e) {
        this.setState({
            showExplanation: !(this.state.showExplanation)
        });
    }

    render() {

        let leftArrow;
        leftArrow = (<div className='leftarrowcard' onClick={this.handleClick_back}>
            <span className={'fas fa-angle-left fa-3x'}></span>
        </div>);
        let rightArrow;
        rightArrow = (<div className='rightarrowcard' onClick={this.handleClick_foward}>
            <span className={'fas fa-angle-right fa-3x'}></span>
        </div>);
        let audioPlayerrow;
        if (this.props.audioPlayer === false) {
            audioPlayerrow = null;
        } else {
            audioPlayerrow = (<div className='row justify-content-md-center text-center'>
                <div className='col-md-auto right'>
                    <AudioPlayer ref={this.audioRef} src={this.getAudioSrc()} />
                </div>
                <div className='col-md-auto left'>
                    <RadioButtonsGroup onChildClick={this.handlerGenderChange} />
                </div>
            </div>);
        }
        //For grammar and translation pages (translation textBox)
        let translationBox;
        if(this.props.translationBox === false) {
            translationBox = null;
        } else {
            translationBox = (<div className="form-row">
                    <div className="col">
                        <TextField id="outlined-basic" label="My translation" variant="outlined"
                            multiline
                            fullWidth
                            rowsMax={4}
                            onChange={this.handleMyTranslationChange}
                            rows={3}
                            helperText={this.state.helperText}
                            //error={this.state.translationError}
                            ref={el => this.translationValue = el}
                        />
                    </div>
                </div>)
        }


        return (
            <div ref={this.cardTerm} className="cardSection">
                <div className="scaled">
                <div className='d-flex flex-row align-items-center'>
                    <div className='leftArrow p-5' onClick={this.handleClick_back}>
                        {leftArrow}
                    </div>
                    <div className=''>
                        <div className={this.state.styleContainter} >
                            <div className='card-body'>
                                <CardFront
                                    termtext={this.state.text}
                                    fontSize={this.props.fontSize}
                                    reference={this.state.reference}
                                    flipCard={this.state.flipCard}
                                    termdefinition={this.state.definition}
                                    maxItems={(this.state.maxIndex + 1)}
                                    currentCard={(this.state.currentCard + 1)}
                                    termexplanation={this.state.definition}
                                    displayExplanationIcon={this.props.displayExplanationIcon}
                                    displayShuffleButton={this.props.displayShuffleButton}
                                    verbParsingShuffle={this.props.verbParsingShuffle}
                                    shuffleListClick={this.ShuffleList}
                                    displayDefinitionButton={this.props.displayDefinitionButton}
                                    showExplanation={this.state.showExplanation}
                                    showExplanationIconClick={this.showExplanationIconClick}
                                    explanationIconText={this.props.explanationIconText}
                                    explanationIconSubText={this.props.explanationIconSubText}
                                    onVerbShuffle={this.props.onVerbShuffle}
                                    SetCurrentCard={this.SetCurrentCard}
                                    isLoading={this.props.isLoading}
                                />
                                {/* <CardBack termdefinition={this.state.definition} /> */}
                            </div>
                        </div>
                    </div>
                    <div className='rightArrow p-5' onClick={this.handleClick_foward}>
                        {rightArrow}
                    </div>
                </div>
                {audioPlayerrow}
                    {translationBox}
                    </div>
            </div>



        )
    }
}
