import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Redirect } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import SignUp from './SignUp/SignUp';
import { sessionTools } from '../_services/sessionTools';
import { Connector } from '../_api/connector';


import { authenticationService } from '../_services/authenticationService';
import { staticList } from '../_services/staticList';


const validationSchema = yup.object({
    username: yup
        .string('Enter your Username')
        .required('Username is required'),
    password: yup
        .string('Enter your password')
        .min(6, 'Password should be of minimum 6 characters length')
        .required('Password is required'),
});
const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(400 + theme.spacing(2) * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    Paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        //marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignInForm() {
    const classes = useStyles();
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [openValidation, setOpenValidation] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');    
    const [logginSuccessfull, setSucess] = React.useState(false);
    const [homepage] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: validationSchema,

        onSubmit: (values) => {
            setSubmitting(true);
            authenticationService.login(values.username
                , values.password
                , setSubmitting
                , setOpenValidation
                , setAlertMessage
                , setLogginIn);
        },
    });
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenValidation(false);
    };

    const setLogginIn = (value) => {
        if (value === true) {
            staticList.getCountry();
            staticList.getStates();
            if (sessionTools.getCookie('btu_')['biblicalSubscription']) {
                localStorage.setItem('bhlt', true);
                //staticList.getVocabulary_VocabularySets();
                //staticList.getVocabulary_VocabularyStudyList();
                //staticList.getBiblical_AlphabetSets();
                //staticList.getBiblical_UnitSets();
                //staticList.getVocabulary_VocabularyStudyList();
            }
            setSucess(true);
        }        
    };

    

    Connector.resetToken(false);
    var checkUserState = localStorage.getItem('bhlt');
    var currentUser = sessionTools.getCookie('btu_')
    if (checkUserState && !currentUser) {
        authenticationService.logout();
        window.location.reload();
    }

    if (logginSuccessfull) {
        return <Redirect to='/Components' />
    }
    else {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Paper elevation={3} className={classes.layout}>
                    <div className={classes.Paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">Sign in</Typography>
                        <form onSubmit={formik.handleSubmit} className={classes.form}>
                            <Backdrop className={classes.backdrop} open={isSubmitting} >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="username"
                                name="username"
                                label="Username"
                                autoFocus
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="password"
                                type="password"
                                id="password"
                                label="Password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Sign In</Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="/BiblicalHebrew/ResetForm" variant="body2">
                                        Forgot password?</Link>
                                </Grid>
                                <Grid item>
                                    <SignUp homepage={homepage} />
                                </Grid>
                            </Grid>
                            
                            <Snackbar open={openValidation} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="warning">
                                    {alertMessage}
                                </Alert>
                            </Snackbar>
                        </form>

                    </div>
                </Paper>
            </Container>
        );
    }
}


