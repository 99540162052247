import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import SignUp from './SignUp/SignUp';
import { Connector } from '../_api/connector';

import { authenticationService } from '../_services/authenticationService'


export class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        };
    }
    goAbout = () => {
        return <Redirect to='/About' />
    }

    componentDidMount() {            
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }

    render() {
        const { currentUser } = this.state;
        Connector.resetToken();
        return (
            <main role="main">
                <div className="jumbotron jumbotron-fluid">
                    <div className="container text-light">
                        <h1 className="display-3">BIBLICAL HEBREW</h1>
                        <h3 className="display-4">Learning Tool</h3>
                        <p>The Biblical Hebrew Learning Tool is designed for beginning and intermediate students of Biblical Hebrew.</p>
                        <p></p>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h2>About</h2>
                            <p>
                                The Biblical Hebrew Learning Tool is designed for beginning and intermediate students of Biblical Hebrew.
                                The Learning Tool features five components: Vocabulary, Pronunciation, Verb Parsing, Grammar, and Translation.
                            </p>
                            <p>
                                <Link to="/About">
                                    <button className="btn btn-secondary" >View details &raquo;</button>
                                </Link>
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h2>Contact</h2>
                            <p>For information about the program, please contact <a href="mailto:donald_parry@byu.edu">donald_parry@byu.edu</a>. </p>
                            <p>For information about purchasing a subscription, please contact <a href="mailto:catalog@byu.edu">catalog@byu.edu</a> or go to our
                                <a href="https://creativeworks.byu.edu/CreativeworksStore/ContactForm?"> Contact page</a>. </p>
                            <p>For technical difficulties, please contact <a href="mailto:cwprog@byu.edu">cwprog@byu.edu</a> or go to our
                                <a href="https://creativeworks.byu.edu/CreativeworksStore/ContactForm?"> Contact page</a>.</p>
                        </div>
                        <div className="col-md-4">
                            <h2>Subscription Details</h2>
                            <p>Biblical Hebrew is available through two subscription plans: $30 for six months or $50 for one year.
                                Click on the button below to sign up for or view your subscription. </p>  <br />
                            {!currentUser &&
                                <SignUp homepage="true"/>
                            }
                            {currentUser &&
                                <Link to="/MyAccount">
                                    <button className="btn btn-secondary">View Subscription</button>
                                </Link>
                            }
                        </div>
                    </div>
                    <hr />
                </div>
            </main>
        );
    }
}
