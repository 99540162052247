import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { UserService } from '../_services/userService'
//import Backdrop from '@material-ui/core/Backdrop';
//import CircularProgress from '@material-ui/core/CircularProgress';
import { sessionTools } from '../_services/sessionTools';
import { Connector } from '../_api/connector';
import './FlippingCardt.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    course: {
        padding: theme.spacing(2),
        textAlign: 'left',
        minHeight: 235
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function SkeletonBox(props) {
    return (
        <Grid container wrap="nowrap">
            <Box width={210} marginRight={0.5} my={5}>
                <Skeleton variant="rect" width={210} height={118} />
                <Box pt={0.5}>
                    <Skeleton />
                    <Skeleton width="60%" />
                </Box>
            </Box>
        </Grid>
    );
}


export function SkeletonCourse(props) {
    const classes = useStyles();
    //console.log(props)
    let message='';
    if(props.expired) {        
        message = 'Your subscription has expired. To keep using please repurchase subscription from "My Account" menu.';
    }
//console.log(message)
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.flexGrow}
        >
            <Paper className={classes.paper}>
                <Grid container /*spacing={3}*/>
                    <Grid item xs>
                        <SkeletonBox />
                    </Grid>
                    <Grid item xs>
                        <SkeletonBox />
                    </Grid>
                    <Grid item xs>
                        <SkeletonBox />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container wrap="nowrap" /*spacing={2}*/>
                            <Grid item>
                                <Avatar>B</Avatar>
                            </Grid>
                            <Grid item xs>
                                <Typography>{message}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid container /*spacing={3}*/>
                    <Grid item xs>
                        <SkeletonBox />
                    </Grid>
                    <Grid item xs>
                        <SkeletonBox />
                    </Grid>
                    <Grid item xs>

                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}


function Course(props) {
    const classes = useStyles();
    const { title, text, link } = props;
    return (
        <Paper elevation={3} className={classes.course}>
            <Typography variant="h5" gutterBottom>{title}</Typography>
            <Typography variant="body2" gutterBottom>{text}</Typography>
            <Link to={link}><Button variant="contained" color="primary">Go to component</Button></Link>
        </Paper>
    );
}


function CoursesGrid(props) {
    //const classes = useStyles();
    return (
        <div className="container mt-3">
            <Grid container spacing={3}>
                <Grid item xs>
                    <Course title='Pronunciation'
                        text='This component allows users to practice pronouncing (1) the Hebrew alphabet; (2) the most frequent 1,000 Hebrew words; and (3) 27 selected literary units (about 500 verses) from the Hebrew Bible. 
                    Users see the letter, word, or phrase as they listen to a female or male voice (modern Israeli pronunciation) enunciate the alphabet and words.'
                        link='/Pronunciation'
                    />
                </Grid>
                <Grid item xs /*spacing={3}*/>
                    <Course title='Vocabulary'
                        text='This component presents the 1,000 most frequent words in the Hebrew Bible,
                    listed and grouped according to their frequency. Users can select and study vocabulary words, shuffle the list, listen to the pronunciation (either a male or female voice), and show the translation.'
                        link='/Vocabulary'
                    />
                </Grid>          
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs /*spacing={3}*/>
                    <Course title='Verb Parsing'
                        text='This component comprises approximately 3,000 verbs, selected directly from the Hebrew Bible, for users to parse. Users may select any or all of the following forms—Qal, Niphal, Piel, Pual, Hiphil, Hophal, and Hithpael, as well as any or all of the following tenses—perfect, imperfect, participle, infinitive, and imperative. The “Explanation” button provides verb parsing indicators, so that users can improve their parsing skills.'
                        link='/VerbParsing'
                    />
                </Grid>
                <Grid item xs>
                    <Course title='Grammar'
                        text='This component contains over 2,000 phrases derived from the Hebrew Bible, grouped by both grammatical concept and vocabulary word frequency. Users can type their translation into a textbox and compare their response to the correct translation using the "Translate" button.'
                        link='/Grammar'
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs /*spacing={3}*/>
                    <Course title='Translation'
                        text='This component provides translation exercises from 28 different literary units (about 500 biblical verses), including examples from historical narrative, poetry, or legal text phrases and verses from the Hebrew Bible. Users can type their translation into a textbox and compare their response to the correct translation, derived from either the New International or King James versions of the Bible.'
                        link='/Translation'
                    />
                </Grid>
                <Grid item xs>

                </Grid>
            </Grid>
        </div>
    );
}

//function LoadingBox(props) {
//    const classes = useStyles();
//    const { loading } = props;
//    return (
//        <Backdrop className={classes.backdrop} open={loading} >
//            <CircularProgress color="inherit" />
//        </Backdrop>
//    );
//}

export class Components extends Component {
    static displayName = Components.name;
    constructor(props) {
        super(props);
        this.state =
        {
            subsExpired: false,
            loading: true,
            currentUser: sessionTools.getCookie('btu_'),
        };
        this.setExpiration = this.setExpiration.bind(this);
    }
    componentDidMount() {
        UserService.isSubscriptionExpired(this.setExpiration);
        //this.setState({ authenticate:authenticationService.isSubscribed() })
        //authenticationService.currentUser.subscribe(x => {
        //    if (x != null) {
        //        try {
        //            this.setState({ currentUser: x, currentSubscription: JSON.parse(x).biblicalSubscription });
        //            //console.log('subscribe')
        //            //console.log(x.biblicalSubscription)
        //        }
        //        catch (e) {
        //            this.setState({ currentUser: x, currentSubscription: x.biblicalSubscription });
        //            //console.log('failsubscribe')
        //        }
        //    }
        //});
    }
    setExpiration(value) {        
        this.setState({ subsExpired: value, loading: false });
    }
    render() {
        const isExpired = this.state.subsExpired;
        Connector.resetToken();
        //const currentUser = this.state;
        //const currentSubscription = this.state;
        if (!this.state.currentUser) {
            return <Redirect to='/Sign-in' />;
        }
        else {
            var subscription = this.state.currentUser.biblicalSubscription
            if (!subscription) {
                let infoCourse;
                infoCourse = <SkeletonCourse expired={true} />
                return infoCourse;
            }
            else {
                let infoCourse;
                if (isExpired) {
                    infoCourse = <SkeletonCourse expired={true} />
                } else {
                    //console.log(isExpired)
                    infoCourse = <CoursesGrid />
                }
                return infoCourse;
            }
        }
    }
}
