import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


export const severty_error = "error";
export const severty_warning = "warning";
export const severty_info = "info";
export const severty_success = "success";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


export default function ValidationMessage(props) {    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }            
        props.setshowWarningWrong(false);       
      };  

    return (
        <Snackbar open={(props.open)} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.severity}>
          {props.message}
        </Alert>
       </Snackbar>
    );
}
