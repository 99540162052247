import React, { Component } from 'react';



export class CashNetTransfer extends Component {
    static displayName = CashNetTransfer.name;
    constructor() {
        super();
        this.state = {
            fname: '',
            lname: '',
            email: '',
        };
    }

    onChange = (e) => {
        /*
          Because we named the inputs to match their
          corresponding values in state, it's
          super easy to update the state
        */
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        //const { fname, lname, email } = this.state;
        return (
            <form id="CashNetForm" method="post" action="https://train.cashnet.com/WEBCREAT" name="CASHNetForm">
                <input type="hidden" name="custcode" value="tcs20200302080914912" />
                <input type="hidden" name="addr" value="785 E 560 N" />
                <input type="hidden" name="city" value="Provo" />
                <input type="hidden" name="state" value="Utah" />
                <input type="hidden" name="zip" value="84606" />
                <input type="hidden" name="country" value="United States" />
                <input type="hidden" name="email" value="fponceci@gmail.com" />
                <input type="hidden" name="itemcode1" value="7369G" />
                <input type="hidden" name="amount1" value="5.36" />
                <input type="hidden" name="qty1" value="1" />
                <input type="hidden" name="desc1" value="Anelida and Arcite" />
                <input type="hidden" name="ref1type1" value="GL" />
                <input type="hidden" name="ref1val1" value="W60000204520STXUT" />
                <input type="hidden" name="itemcnt" value="1" />


                <input id="SubmitButton" type="submit"
                    value="Purchase"
                    className="button" />
            </form>
        );
    }
}