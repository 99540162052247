var formModel = {
  formId: 'checkoutForm',
  formField: {
    guid: {
      name: 'guid'  ,
      label: 'Guid'    
    },
    email: {
      name: 'email',
      label: 'Email',
      requiredErrorMsg: 'Email is required',
      requiredExistErrorMsg: 'This email address is already being used.',
      requiredValidErrorMsg: 'Valid email is required'
    },
    username: {
      name: 'username',
      label: 'Username',
      requiredErrorMsg: 'Username is required',
      requiredExistErrorMsg: 'This Username is already being used.',
      requiredLengthErrorMsg: 'Username must be at least 6 characters'
    },
    password: {
      name: 'password',
      label: 'Password',
      requiredErrorMsg: 'Password is required',
      requiredLengthErrorMsg: 'Password must be at least 6 characters'
    },
    passwordRepeat: {
      name: 'passwordRepeat',
      label: 'Password Repeat',
      requiredErrorMsg: 'Password repeat is required',
    },
    firstName: {
      name: 'firstName',
      label: 'First name',
      requiredErrorMsg: 'First name is required'
    },
    lastName: {
      name: 'lastName',
      label: 'Last name',
      requiredErrorMsg: 'Last name is required'
    },
    address1: {
      name: 'address1',
      label: 'Address Line 1',
      requiredErrorMsg: 'Address Line 1 is required'
    },
    address2: {
      name: 'address2',
      label: 'Address Line 2'
    },
    city: {
      name: 'city',
      label: 'City',
      requiredErrorMsg: 'City is required'
    },
    state: {
      name: 'state',
      label: 'State/Province/Region',
        requiredErrorMsg: 'State/Province/Region is required',
      invalidStateMsg: 'Addresses in the United States must select a valid state'
    },
    zipcode: {
      name: 'zipcode',
      label: 'Zipcode',
      requiredErrorMsg: 'Zipcode is required',
      invalidErrorMsg: 'Zipcode is not valid (e.g. 70000)'
    },
    country: {
      name: 'country',
      label: 'Country',
      requiredErrorMsg: 'Country is required',      
    },
    subscription: {
      name: 'subscription',
      label: 'Subscription plans',
      requiredErrorMsg: 'Subscription plan is required'
    }
  }
};

export default formModel;
