import React, { Component } from 'react';
import { Card } from './CardContainer';
import { Quiz } from './InlineQuiz';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { Redirect } from 'react-router-dom'

import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';

import { sessionTools } from '../_services/sessionTools';
import { config } from './Config.custom'

import "./FlippingCardt.scss"


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    RadioGroup: {
        fontSize: '50%',
        margin: theme.spacing(0),
        display: 'flex',
        flexDirection: 'row'
    }
}));


export default function FormDialog({ onDialogClick }) {
    const [open, setOpen] = React.useState(true);
    //const [valuetext] = React.useState('');
    const handleClickOpen = () => {
        setOpen(true);
    };
    const [dvforms, setDvforms] = React.useState([]);
    const [tense, setTense] = React.useState([]);
    const [slideValue, setSliderValue] = React.useState(50);
    const handleClose = () => {
        setOpen(false);

        onDialogClick(dvforms, tense, slideValue);
    };
    const [state, setState] = React.useState({
        qal: false,
        niphal: false,
        piel: false,
        pual: false,
        hiphil: false,
        hophal: false,
        hithpael: false,
        perfect: false,
        imperfect: false,
        imperative: false,
        participle: false,
        infinitive: false,
    });
    const handleChangeVerb = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        if (event.target.checked) {
            let value = event.target.name;
            setDvforms(dvforms => [...dvforms, value]);
        }
        else {
            var toRemove = event.target.name;
            var index = dvforms.indexOf(toRemove);
            if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                dvforms.splice(index, 1);
            }
        }
    };
    const handleChangeTense = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        if (event.target.checked) {
            let value = event.target.name;
            setTense(tense => [...tense, value]);
        }
        else {
            var toRemove = event.target.name;
            var index = tense.indexOf(toRemove);
            if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                tense.splice(index, 1);
            }
        }
    };

    const handleChangeSlider = (event, newValue) => {
        setSliderValue(newValue);
    };

    const classes = useStyles();
    return (
        <div>
            <Tooltip title="Setting verb list">
                <Button variant="outlined"
                    color="primary"
                    size="small"
                    //startIcon={<MenuIcon />}
                    onClick={handleClickOpen}>
                    Settings
            </Button>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Settings</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Derived Verbs</FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={state.qal} onChange={handleChangeVerb} name="qal" color="primary" />}
                                label="Qal" />
                            <FormControlLabel
                                control={<Checkbox checked={state.niphal} onChange={handleChangeVerb} name="niphal" color="primary" />}
                                label="Niphal" />
                            <FormControlLabel
                                control={<Checkbox checked={state.piel} onChange={handleChangeVerb} name="piel" color="primary" />}
                                label="Piel" />
                            <FormControlLabel
                                control={<Checkbox checked={state.pual} onChange={handleChangeVerb} name="pual" color="primary" />}
                                label="Pual" />
                        </FormGroup>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={state.hiphil} onChange={handleChangeVerb} name="hiphil" color="primary" />}
                                label="Hiphil" />
                            <FormControlLabel
                                control={<Checkbox checked={state.hophal} onChange={handleChangeVerb} name="hophal" color="primary" />}
                                label="Hophal" />
                            <FormControlLabel
                                control={<Checkbox checked={state.hithpael} onChange={handleChangeVerb} name="hithpael" color="primary" />}
                                label="Hithpael" />
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Tense</FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={state.perfect} onChange={handleChangeTense} name="perfect" color="primary" />}
                                label="Perfect" />
                            <FormControlLabel
                                control={<Checkbox checked={state.imperfect} onChange={handleChangeTense} name="imperfect" color="primary" />}
                                label="Imperfect" />
                            <FormControlLabel
                                control={<Checkbox checked={state.imperative} onChange={handleChangeTense} name="imperative" color="primary" />}
                                label="Imperative" />
                        </FormGroup>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={state.participle} onChange={handleChangeTense} name="participle" color="primary" />}
                                label="Participle" />
                            <FormControlLabel
                                control={<Checkbox checked={state.infinitive} onChange={handleChangeTense} name="infinitive" color="primary" />}
                                label="Infinitive" />
                        </FormGroup>
                    </FormControl>
                    <FormGroup row>
                        <Typography id="discrete-slider" gutterBottom>
                            Maximum Sample Size
                        </Typography>
                        <Slider
                            //added 'key' attribute to stop an error in console
                            key={`slider-${slideValue}`}
                            defaultValue={slideValue}
                            //getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider-always"
                            valueLabelDisplay="auto"
                            step={50}
                            marks
                            min={50}
                            max={300}
                            color="primary"
                            onChange={handleChangeSlider}
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function RadioTypeVerb({ radioChangeType }) {
  
    const [typeVerb, setSelectedValue] = React.useState('hebrewVerb');
    const handleChange = (event) => {
            setSelectedValue(event.target.value);
            radioChangeType(event.target.value);
    };
    const style1 = { display: 'flex', flexDirection: 'row' };
    return (
        <div>
            <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={typeVerb} onChange={handleChange} style={style1}>
                    <FormControlLabel value="hebrewVerb" control={<Radio />} label="Inflected verb" />
                    <FormControlLabel value="rootForm" control={<Radio />} label="Verb Root" />
                </RadioGroup>
            </FormControl>
        </div>
    );
}

//For old shuffle button
export function ShuffleButton({ shuffleChange }) {      
    function handleClick(e) {
        e.preventDefault();
        shuffleChange(e);
    }
    return (
        <div className="text-right">
            <Tooltip title="Fetch a new set of cards">
                <Button onClick={handleClick}
                    variant="outlined"
                    color="primary"
                    size="big" >
                    New List
            </Button>
            </Tooltip>
        </div>
    );
}


export class VerbParsing extends Component {
    static displayName = VerbParsing.name;

    constructor(props) {
        super(props);
        this.cardChild = React.createRef()
        this.awsPathAudio = ''; ///we dont need it in this course
        this.state = {
            ready: false,
            typeVerb: 'inflected',
            vocabularywordsList: [],
            defaultText: '',
            defaultDefinition: '',
            groupName: null,
            audioUrl: null,
            fontSize: 64,
            textPropertyName: 'hebrewVerb',
            definitionPropertyName: 'explanationText',
            currentCard: 0,
            dvForm: 'qal',
            tense: 'perfect',
            maxSize: 50,
            cardNum: 1,
            currentUser: sessionTools.getCookie('btu_'),
            noresults:false
        };
        this.getVerbLists = this.getVerbLists.bind(this);
        this.dialogActionClick = this.dialogActionClick.bind(this);
        this.typeVerbchange = this.typeVerbchange.bind(this);
        //this.onShuffleList = this.onShuffleList.bind(this);
    }
    async componentDidMount() {
    }

    getVerbLists(pverbForm, ptense, pmaxList) {
        try {
            if(pverbForm === ''){
                pverbForm = 'qal,niphal,piel,pual,hithpael,hophal,hiphil';
            }
            if(ptense === ''){
                ptense = 'perfect,imperfect,imperative,infinitive,participle';
            }
            this.setState({
                dvForm: pverbForm,
                tense: ptense,
                maxSize: pmaxList,
            }, () => { 
                // console.log(this.state.dvForm)
                // console.log(this.state.tense)
                // console.log(this.state.maxSize)
                fetch(config.api_url + 'VerbParsing/GetQuery?dvform=' + this.state.dvForm + '&tense=' + this.state.tense + '&maxSize=' + this.state.maxSize)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result) {
                                this.onSetCurrentCard(0);
                                //console.log(result)
                                if(result.length === 0){
                                    //console.log('no results')
                                    this.setState({
                                        noresults:true,
                                        ready:true,
                                        vocabularywordsList: result,
                                        defaultText: 'No available cards for current settings',
                                        defaultDefinition: ''
                                    });

                                }
                                else {
                                    this.setState({
                                        noresults:false,
                                        ready: true,
                                        vocabularywordsList: result,
                                        defaultText: result[0].hebrewVerb,
                                        defaultDefinition: result[0].explanationText
                                    });
                                }
                            }
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            console.log(error);
                        }
                    
                    )
            });
            } catch (e) {
                console.info(e);
            }
        }
    
    dialogActionClick(pverbForm, ptense, maxList) {
        var verbForm; var tense;
        if (pverbForm.length > 0) {
            verbForm = pverbForm.join(",")
        } else {
            verbForm = "";
        }
        if (ptense.length > 0) {
            tense = ptense.join(",")
        } else {
            tense = "";
        }
        this.getVerbLists(verbForm, tense, maxList);
    }
    typeVerbchange(type) {
        if (this.state.noresults === false) {
            this.setState({
                textPropertyName: type
            });
        }
    }
    onSetCurrentCard(index) {
        this.setState({ currentCard: index });
        this.setState({ cardNum: this.state.cardNum + 1 });
    }
    onShuffleList() {
        this.getVerbLists(this.state.dvForm, this.state.tense, this.state.maxSize);
    } 
    render() {
        if (!this.state.currentUser) {
            return <Redirect to='/Sign-in' />
        }
        else {
            var subscription = this.state.currentUser.biblicalSubscription
            if (!subscription) {
                return <Redirect to='/MyAccount' />
            } else {
                return (
                    <div className="">
                        <div className="d-flex flex-column align-items-start">
                            <div>
                                <Typography variant="h6" gutterBottom>
                                    Verb Parsing
                                </Typography>
                            </div>
                        <div className="form-row">
                            <div className="col-sm text-left">
                                <FormDialog onDialogClick={this.dialogActionClick} />
                            </div>
                            <div className="col-6 text-center">
                                <RadioTypeVerb radioChangeType={this.typeVerbchange} />
                            </div>
                            {/* <div class="col-sm text-right">
                            <ShuffleButton shuffleChange={this.onShuffleList.bind(this)} />
                        </div> */}
                            </div>
                            
                        <Card ref={this.cardChild}
                            wordsList={this.state.vocabularywordsList}
                            defaultText={this.state.defaultText}
                            defaultDefinition={this.state.defaultDefinition}
                            pathaudioFile={this.state.audioUrl}
                            audioPlayer={false}
                            vocabularySetId={this.state.vocabularySetId}
                            groupName={this.state.groupName}
                            fontSize={this.state.fontSize}
                            textPropertyName={this.state.textPropertyName}
                            definitionPropertyName={this.state.definitionPropertyName}
                            onSetCurrentCard={this.onSetCurrentCard.bind(this)}
                            onVerbShuffle={this.onShuffleList.bind(this)}
                            displayExplanationIcon={true}
                            displayShuffleButton={false}
                            verbParsingShuffle={true}
                            translationBox={false}
                            explanationIconText='Explanation'
                            explanationIconSubText='Show Explanation Text'
                               />
                            <div className="">
                            <div className="">
                                <Quiz
                                    wordsList={this.state.vocabularywordsList}
                                    currentCard={this.state.currentCard}
                                    cardNum={this.state.cardNum}
                                    noresults={this.state.noresults}
                                />
                            </div>
                                </div>
                                </div>

                        </div>
                        
                );
            }
        }
    }
}
