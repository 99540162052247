import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import InputField from "../SignUp/FormModel/inputField";
import { Connector } from "../../_api/connector.js"
import { Link } from 'react-router-dom';

export default function AccountForm(props) {
  const {
    formField: { email, username, password, passwordRepeat },
    } = props;
    Connector.resetToken();
    var StudentButton = '';
    if (!props.casUser) {
        StudentButton = <Link to="/CASSignUp" tabindex="-1"><button className = "btn btn-secondary" tabIndex="-1"> Sign up as a BYU Student </button ></Link >;
    }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Account Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField name={email.name} label={email.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={username.name} label={username.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={password.name}
            label={password.label}
            fullWidth
            type="password"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={passwordRepeat.name}
            label={passwordRepeat.label}
            fullWidth
            type="password"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
            {StudentButton}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
