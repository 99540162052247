import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import InputField from "../FormModel/inputField";
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core';

function SelectField(props) {
  const { label, data, name, myChange, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
    }
    
    function _receiveChanges() {
        props.myChange(selectedValue);
    }
    if (props.disable) {
        return (

            <FormControl {...rest} error={isError}>
                <InputLabel>{label}</InputLabel>
                <Select {...field} value={selectedValue}
                >
                    {data.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                {_receiveChanges()}
                {_renderHelperText()}
            </FormControl>
        );
    }
    else {
        return (< InputField name = { name } label = { label } fullWidth />)}
}

SelectField.defaultProps = {
  data: []
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired
};
export default SelectField;
