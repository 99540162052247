import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    username,
    email,
    password,
    passwordRepeat,
    firstName,
    lastName,
    address1,
    city,
    zipcode,
    country,  
    state,    
    subscription,
    guid
  }
} = checkoutFormModel;

var defaultValues = {
  [username.name]: '',
  [email.name]: '',
  [password.name]: '',
  [passwordRepeat.name]: '',
  [firstName.name]: '',
  [lastName.name]: '',
  [address1.name]: '',
  [city.name]: '',
  [zipcode.name]: '',
  [country.name]: '',  
  [state.name]: '',  
  [subscription.name]: '',  
  [guid.name]: ''
};

export default defaultValues;
