import { BehaviorSubject } from 'rxjs';
import { config } from '../components/Config.custom';
import jwt from 'jsonwebtoken'
import { sessionTools } from '../_services/sessionTools';

//import { handleResponse } from '@/_helpers';
const currentUserSubject = new BehaviorSubject(sessionTools.getCookie('btu_'));
export const authenticationService = {
    login,
    logout,
    isAuthenticate,
    isSubscribed,
    VerifyCaptcha,
    GetToken,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function login(username, password, setSubmitting, setOpenValidation, setAlertMessage,setLogginIn) {
    var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
    fetch(config.api_cwo + `User/SignIn/`, {
        method: 'POST',
        headers: {
            'Authorization': bearer,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username: username, password: password })
    }).then(res => res.json())
        .then(
            (result) => {
                //console.log(result)
                if (result.result === "0") {
                    sessionTools.setCookie('btu_', result)
                    sessionTools.setCookie('btl_', true);
                    currentUserSubject.next(JSON.stringify(result));
                    sessionTools.setCookie('bt_', result.token);
                    setSubmitting(true);
                    setLogginIn(true);
                } else {
                    sessionTools.setCookie('btl_', false);
                    sessionTools.removeCookie('btu_');
                    sessionTools.removeCookie('bt_');
                    currentUserSubject.next(null);
                    if (result.validation) {
                        setOpenValidation(true);
                        setAlertMessage(result.validation);
                    }
                    setLogginIn(false);
                }
                setSubmitting(false);
            },
            (error) => {
                console.log(error)
                return [];
            }
        ).catch(error => { console.log(error); return []; });
};


function logout() {
    // remove user from local storage to log user out
    sessionTools.removeCookie('btu_');
    sessionTools.removeCookie('biblicalLearningGuid');
    sessionTools.removeCookie('chekoutForm');
    sessionTools.removeCookie('btl_');
    sessionTools.removeCookie('bt_');
    localStorage.removeItem('states');
    localStorage.removeItem('countries');
    localStorage.removeItem('bhlt');
    localStorage.removeItem('hebrew_VocabularySets');
    localStorage.removeItem('hebrew_VocabularyStudyList');
    localStorage.removeItem('hebrew_AlphabetSets');
    localStorage.removeItem('hebrew_BiblicalUnitSets');
    localStorage.removeItem('hebrew_VocabularyStudyList');
    localStorage.removeItem('checkoutForm');
    currentUserSubject.next(null);
}


function isAuthenticate() {    
    const _logginIn = sessionTools.getCookie('btl_');
    if (_logginIn) {
        //validate information
        //console.log(_logginIn);
        const user = sessionTools.getCookie('btu_');
        if (user) {
            if (TokenExpired(user.token)) {
                logout();
                return false;
            }
            else {
                return true;
            }
        }
    }
    else {
        return false;
    }

}

function isSubscribed() {
    const _logginIn = sessionTools.getCookie('btl_');
    if (_logginIn) {
        //validate information
        //console.log(_logginIn);
        const user = sessionTools.getCookie('btu_');
        if (user) {
            if (TokenExpired(user.token)) {
                logout();
                return "login";
            }
            else if (user.currentSubscription === 0) {
                return "nosubscription";
            }
            else {
                return "good";
            }
        }
    }
    else {
        //console.log("nologin")
        return "login";
    }

}


function TokenExpired(_token) {
    if (_token) {
        var isExpired = false;
        var decodedToken = jwt.decode(_token, { complete: true });
        if (decodedToken) {
            var dateNow = new Date();
            if (decodedToken.payload.exp * 1000 < dateNow.getTime()) {
                isExpired = true;
            }
        }
        else//invalid
            isExpired = true;
        return isExpired;
    }
    else
        return true;
}


function VerifyCaptcha(value, setCaptchaVerified) {
    var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': bearer,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ captchaResponse: value, secret: config.captcha_secret })
    };
    fetch(config.api_cwo + `Authorization/Captcha/`, requestOptions)
        .then(res => (res.json()))
        .then(
            (result) => {
                if (result.success) {
                    setCaptchaVerified(true);
                    console.log('Captcha Passed');
                } else {
                    setCaptchaVerified(false);
                    console.log('Captcha Failed');
                    console.log(result)
                }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setCaptchaVerified(false);
                console.log('Captcha Errror');
            }
        )
        .catch(function (error) {
            ///if status code 401. Need help here
            console.log('Captcha Verification Failed: ', error)
            setCaptchaVerified(false);
        });
}

function GetToken() {
    const token = sessionTools.getCookie('bt_');
    return token
}