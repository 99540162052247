import { BehaviorSubject } from 'rxjs';
import { config } from '../components/Config.custom'
import { sessionTools } from './sessionTools';
//import { handleResponse } from '@/_helpers';

const currentUserSubject = new BehaviorSubject(sessionTools.getCookie('btu_'));

const AuthenticatePublicRequest = {
  Username: process.env.REACT_APP_CLIENT_ID,
  Password: process.env.REACT_APP_KEY/*,
Token:''*/
};
export const creativeworksStore = {
    saveOrder,
    getGLCode,
    getCustomerName,
  createAccount,
  getCashNetConfiguration,
  resubscribe,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value }
};

function saveOrder(values,  casUser, setGoToCashNet, setshowWarningWrong, setisSubmitting) {
    var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
    //console.log(values.state);
    values.react_app_client_id = AuthenticatePublicRequest.Username;
    values.react_app_key = AuthenticatePublicRequest.Password;
    if (casUser) {
        values.byuUser = 1;
    }
    else {
        values.byuUser = 0;
    }
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': bearer,
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(values)
  };
  fetch(config.api_cwo + 'BiblicalLearningOrder', requestOptions)
    .then(res => (res.json()))
    .then(
        (result) => {
        if (result.result === "0") {
            values.guid = result.data.guid;
            setGoToCashNet(true);
        } else {
          setshowWarningWrong(true);
          setGoToCashNet(false);
          setisSubmitting(false);
        }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setshowWarningWrong(true);
        setGoToCashNet(false);
          setisSubmitting(false);
      }
    )
    .catch(function (error) {
      ///if status code 401. Need help here
      setshowWarningWrong(true);
      setGoToCashNet(false);
        setisSubmitting(false);
    });
}

function getGLCode(country, state, casUser, setGLCode) {

    //var data = JSON.parse(localStorage.getItem('states'));
    //var resultArray = data.states.map(function (elm) {
    //    if (elm['abbreviation'] === state) {
    //        //console.log('abbrev');
    //        return elm['name'];

    //    }
    //    else {
    //        //console.log('state');
    //        return state;
    //    }
    //});
    //console.log('getGLCode')

    var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
    fetch(
        config.api_cwo +
        "GLCode?itemName=BiblicalSubscription&countryid=" +
        country +
        "&statename=" +
        state +
        "&casUser=" +
        casUser.toString(),
        {
            method: "GET",
            headers: {
                Authorization: bearer,
                Accept: "application/json",
            },
        }
    )

        .then((res) => res.json())
        .then(
            (result) => {
                if (result.result === "0") {
                    setGLCode(result.gl);
                } else {
                    setGLCode("00000000000000000");
                }
            },
            (error) => {
                console.log(error);
                setGLCode("");
            }
        )
        .catch((error) => {
            console.log(error);
           setGLCode("");
        });
};

function getCustomerName(firstName, lastName, setCustomerName) {
    var custName = firstName + ' ' + lastName;
    setCustomerName(custName);
}


function resubscribe(values, casUser, setGoToCashNet, setshowWarningWrong) {  
    var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
    if (casUser) {
        values.byuUser = 1;
    }
    else {
        values.byuUser = 0;
    }
  const requestOptions = {
    method: 'POST',
    headers: {
       'Authorization': bearer,
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(values)
  };
  fetch(config.api_cwo + 'BiblicalLearningOrder/ReSubscribe', requestOptions)
    .then(res => (res.json()))
    .then(
      (result) => {
        if (result.result === "0") {
          values.guid = result.data.guid;
          sessionTools.setCookie('chekoutForm', values)
          setGoToCashNet(true);
        } else {
          setshowWarningWrong(true);
          setGoToCashNet(false);          
        }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setshowWarningWrong(true);
        setGoToCashNet(false);        
      }
    )
    .catch(function (error) {
      ///if status code 401. Need help here
      setshowWarningWrong(true);
        setGoToCashNet(false); 
        console.log('Resubscribe Error');
    });
}

function createAccount(values) {
  var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': bearer,
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(values)
  };
  fetch(config.api_cwo + 'User/SignUp', requestOptions)
    .then(res => (res.json()))
    .then(
        (result) => {
            sessionTools.setCookie('btu_', result);
        return result;
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
          console.log(error)
          console.log('Create Account Error');
      }
  )

}




function getCashNetConfiguration(casUser, setSettings, setgotoCashNet) {
    var bearer = 'Bearer ' + sessionTools.getCookie('bt_', true);

  return new Promise(function (resolve, reject) {
      fetch(config.api_cwo + `CashNetResponse/GetConfiguration?itemName=BiblicalSubscription&casUser=` + casUser.toString(), {
      method: 'GET',
       headers: {
          'Authorization': bearer,
        'Accept': 'application/json'
      }
    }).then(res => res.json())
      .then(
          (result) => {
          if (result.result === "0") {
              if (setSettings) {
                  setSettings(result)
              }
            if(setgotoCashNet)
              setgotoCashNet(true)

            resolve(result);
          } else {
            resolve(null);
          }
        },
        (error) => {
          console.log(error)
          return [];
        }
      ).catch(error => { console.log(error); return []; });
  });
}