import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Components } from './components/Components';
import { Vocabulary } from './components/Vocabulary';
import { Pronunciation } from './components/Pronunciation';
import { CASSignUp } from './components/CASSignUp';
import { SubscribeStudent } from './components/SubscribeStudent';
import { VerbParsing } from './components/VerbParsing';
import { About } from './components/About';
import { ResetPasswordForm } from './components/ResetPassword';
import './custom.css'
import { Grammar } from './components/Grammar';
import { Translation } from './components/Translation';
import { CashNetTransfer } from './components/CashNetTransfer';
import { PaymentResponse } from './components/PaymentResponse';
import SignInForm from './components/sign-in';
import { Link } from 'react-router-dom';
// Add these lines to the top of the file
import { authenticationService } from './_services/authenticationService'
import { MyAccount } from './components/MyAccount';

import CashNetForm from './components/CashNetForm';




export default class App extends Component {
  static displayName = App.name;
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
        username: '',
      authenticate: false
    };
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }
    componentDidMount() {
        var authenticate = authenticationService.isAuthenticate();
        //console.log(authenticate);
        
        authenticationService.currentUser.subscribe(x => {
            if (typeof x !== 'undefined' & x !== null & JSON.stringify(x) !== '{}') {
                if (x.username) {
                    this.setState({ currentUser: x, username: x.username })
                } else {
                    this.setState({ currentUser: JSON.parse(x), username: JSON.parse(x).username })
                }
            }
        });

        if (!authenticate) {
            this.setState({ currentUser: null, username: '' })
        
        }
        
  }
  componentDidUpdate(prevProps) {
 
  }
  handleLogoutClick() {
    this.setState({username:''})
    authenticationService.logout();
      this.forceUpdate();
  }

  render() {
    const { currentUser } = this.state;
    return (
      <div>
        <byu-header>
          <span slot="site-title">Biblical Hebrew Learning Tool</span>
          <byu-user-info slot="user">
            {!currentUser &&
              <Link slot="login" to="/Sign-In">Sign In   </Link>
            }
            {!currentUser &&
              <button slot="logout"> </button>
            }
            {currentUser &&
              <a slot="logout" href="/BiblicalHebrew/Sign-In" onClick={this.handleLogoutClick}>Sign Out</a>
            }
            {currentUser &&
              <button slot="login"> </button>
            }
            {currentUser &&
              <span slot="user-name" id="user-name">{this.state.username}</span>
            }
          </byu-user-info>
        </byu-header>
        <Layout>
          <Route exact path='/' component={Home} />
          <Route path='/Components' component={Components} />
          <Route path='/Vocabulary' component={Vocabulary} />
          <Route path='/About' component={About} />
          <Route path='/Pronunciation' component={Pronunciation} />
          <Route path='/VerbParsing' component={VerbParsing} />
          <Route path='/Grammar' component={Grammar} />
          <Route path='/Translation' component={Translation} />
          <Route path='/CashNetTransfer' component={CashNetTransfer} />
          <Route path='/PaymentResponse/:id' component={PaymentResponse} />
          <Route path='/Sign-in' component={SignInForm} />
          <Route path='/MyAccount' component={MyAccount} />
          <Route path='/CashNetForm' component={CashNetForm} />
          <Route path='/ResetForm' component={ResetPasswordForm} />
          <Route path='/CASSignUp' component={CASSignUp} />
          <Route path='/SubscribeStudent' component={SubscribeStudent} />
        </Layout>
        <div style={{ marginTop: 20 }}>
          <byu-footer>
            <byu-footer-column>
              <span slot="header">Contact</span>
              <p>
                Creative Works Office
                    <br />
                    3760 HBLL <br />
                    Brigham Young University <br />
                    Provo, Utah 84602 <br />
                    catalog@byu.edu <br /><br />
                <a
                  className="btn btn-secondary"
                  href="https://cwo.byu.edu/"
                  role="button">
                Creative Works
                </a>
              </p>
            </byu-footer-column>

            <byu-footer-column className="double-wide">
              <span slot="header"></span>
            </byu-footer-column>
            <byu-footer-column>
              <span slot="header"></span>
              <byu-social-media-links> </byu-social-media-links>
            </byu-footer-column>
          </byu-footer>
        </div>
      </div>
    );
  }
}
