import React, { Component } from 'react';
import { Card } from './CardContainer';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Redirect } from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { config } from './Config.custom'
import { sessionTools } from '../_services/sessionTools';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        marginTop: 25
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectStudyList: {
        width: 300
    },
    selectStudyListLabel: {
        fontSize: 18,
        fontVariant: 'h3'
    }
}));

export default function SelectList(props) {
    const classes = useStyles();
    const [studylist, setList] = React.useState(10);
    const [wordList, setWordsList] = React.useState('');
    const [biblicalList, setBiblicalList] = React.useState('');

    const handleChange = (event) => {
        setList(parseInt(event.target.value));
        setWordsList('');
        setBiblicalList('');
        props.handleChangeStudyList(parseInt(event.target.value));
    };
    const handleWordsList = (event) => {
        setWordsList(event.target.value);
        props.handleWordsList(event.target.value);
    };
    const handleBiblicalList = (event) => {
        setBiblicalList(event.target.value);
        props.handleBiblicalList(event.target.value);
    };

    const WordsList = () => (
        <FormControl className={classes.formControl}>
            <InputLabel id="select-vocabularyList-Label">Words list</InputLabel>
            <Select labelId="select-vocabularyList-Label"
                id="select-vocabularyList"
                value={wordList}
                onChange={handleWordsList} >
                {
                    props.vocabularysetList.map(data =>
                        <MenuItem value={data.id} key={data.id}>{data.title}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
    const BiblicalUnitList = () => (
        <FormControl className={classes.formControl}>
            <InputLabel id="select-BiblicalUnit-Label">Units</InputLabel>
            <Select labelId="select-BiblicalUnit-Label"
                id="select-BiblicalUnit"
                value={biblicalList}
                onChange={handleBiblicalList}>
                {
                    props.biblicalLiteraryUnits.map(data =>
                        <MenuItem value={data.id} key={data.id}>{data.title}</MenuItem>)
                }
            </Select>
        </FormControl>
    )

    return (
        <div>
            <div className="form-row">
                <div className="col-auto my-1">
                    <div className="form-row">
                        <div className="col-auto my-1">
                            <label className="mr-sm-2" htmlFor="vocabularysetList"><strong>Select Study List...</strong></label>
                            <select onChange={handleChange} className="form-control" id="vocabularysetList" value={studylist}>
                                <option value="10">Alphabet</option>
                                <option value="20">1000 Most Frequent Vocabulary Words</option>
                                <option value="30">Selected Biblical Literary Units</option>
                            </select>
                        </div>
                        <div className="col-auto my-1">
                        </div>
                    </div>
                </div>
                <div className="col-auto my-1">
                    {studylist === 20 ? <WordsList /> : null}
                    {studylist === 30 ? <BiblicalUnitList /> : null}
                </div>
            </div>
        </div>
    );
}

export class Pronunciation extends Component {
    static displayName = Pronunciation.name;
    constructor(props) {
        super(props);
        this.cardChild = React.createRef()
        this.awsPathAudio = 'https://creativeworks-store.s3-us-west-2.amazonaws.com/Material/BiblicalHebrew/audio';
        this.state = {
            vocabularySetId: 1,
            vocabularysetList: [],
            alphabetList: [],
            biblicalLiteraryUnits: [],
            ready: false,
            currentUser: sessionTools.getCookie('btu_'),
            cardwordsList: [''],
            audioUrl: this.awsPathAudio,
            defaultText: null,
            defaultDefinition: null,
            groupName: 'alpha',
            fontSize: 48,
            flipCard: false,
            wordPropertyId: '',

            textPropertyName: 'text',
            definitionPropertyName: 'definition'
        };
        //get data methods
        this.getAlphabet = this.getAlphabet.bind(this);
        this.getVocabularySetList = this.getVocabularySetList.bind(this);
        this.getBiblicalLiteraryUnits = this.getBiblicalLiteraryUnits.bind(this);
        //Handle events
        this.handleChangeStudyList = this.handleChangeStudyList.bind(this);
        this.handleWordsList = this.handleWordsList.bind(this);
        this.handleBiblicalList = this.handleBiblicalList.bind(this);
        this.getBiblicalLiteraryUnitsVerses = this.getBiblicalLiteraryUnitsVerses.bind(this);
    }

    async componentDidMount() {
        this.getAlphabet();
        this.getVocabularySetList();
        this.getBiblicalLiteraryUnits();
    }
    hideError() {
        this.setState({ showError: !this.state.showError });
    }
    getVocabularySetList() {
        try {
            fetch(config.api_url + 'VocabularySets')
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result) {
                            this.setState({
                                ready: true,
                                vocabularysetList: result
                            });
                        }                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);
                    }
                )
        }
        catch (e) {
            console.info(e);
        }
    }
    getVocabularyWordsList(setId) {
        try {
            fetch(config.api_url + 'VocabularyWords')
                .then(res => res.json())
                .then(
                    (result) => {
                        const data = result.filter(d => d.vocabularySetsId === parseInt(setId));
                        if (data) {
                            this.setState({
                                ready: true,
                                cardwordsList: data,
                                groupName: 'vocab' + ("0" + setId).slice(-2) + '-',
                                vocabularySetId: setId,
                                defaultText: data[0].text,
                                defaultDefinition: data[0].definition,
                                audioUrl: this.awsPathAudio + '/vocabulary/' + ("0" + setId).slice(-2) + '/',
                                fontSize: 70
                            }, () => {

                            });
                        }                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);
                    }
                )
        }
        catch (e) {
            console.info(e);
        }
    }
    getAlphabet() {
        try {
            fetch(config.api_url + 'AlphabetSets')
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result) {
                            this.setState({
                                ready: true,
                                alphabetList: result,
                                flipCard: false
                            }, () => {
                                this.handleChangeStudyList(10);
                            });
                        }
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);
                    }
                )
        } catch (e) {
            console.info(e);
        }
    }
    getBiblicalLiteraryUnits() {
        try {
            fetch(config.api_url + 'TranslationSets')
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result) {
                            this.setState({
                                ready: true,
                                biblicalLiteraryUnits: result
                            });
                        };
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);
                    }
                )
        } catch (e) {
            console.info(e);
        }
    }
    getBiblicalLiteraryUnitsVerses(setId) {
        try {
            fetch(config.api_url + 'TranslationVerses/' + setId)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ready: true,
                            cardwordsList: result,
                            groupName: 'verse' + ("0" + setId).slice(-2) + '-',
                            vocabularySetId: setId,
                            defaultText: result[0].text,
                            defaultDefinition: result[0].definition,
                            audioUrl: this.awsPathAudio + '/translation/' + ("0" + setId).slice(-2) + '/',
                            fontSize: 48,
                            flipCard: false
                        }, () => {

                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);
                        this.setState({
                            ready: false,
                            error
                        });
                    }
                )
        } catch (e) {
            console.info(e);
        }
    }
    handleChangeStudyList(optionValue) {
        //10 = Show alphabet list
        if (optionValue === 10) {
            this.setState({
                cardwordsList: this.state.alphabetList,
                audioUrl: this.awsPathAudio + '/alphabet/',
                groupName: 'alpha',
                defaultText: this.state.alphabetList[0].text,
                defaultDefinition: this.state.alphabetList[0].text,
                fontSize: 130,
                flipCard: false,
                wordPropertyId: 'id',
            });
        }
        //20 = show word list by selected words group
        if (optionValue === 20) {
            this.setState({
                cardwordsList: [''],
            }, () => {
                this.setState({
                    defaultText: '',
                    defaultDefinition: '',
                    flipCard: false,
                    wordPropertyId: 'wordId',
                });
            });
        }
        //30 = show biblical list by selected unit
        if (optionValue === 30) {
            this.setState({
                cardwordsList: [''],
            }, () => {
                this.setState({
                    defaultText: '',
                    defaultDefinition: '',
                    flipCard: false,
                    wordPropertyId: 'verseId',
                });
            });
        }
    }

    handleWordsList(value) {
        if (value > 0)
            this.getVocabularyWordsList(value);
    }
    handleBiblicalList(value) {
        if (value > 0)
            this.getBiblicalLiteraryUnitsVerses(value);
    }
 
    render() {
        if (this.state.currentUser == null) {
            return <Redirect to='/Sign-in' />
        }
        else {
            var subscription = this.state.currentUser.biblicalSubscription
            if (!subscription) {
                return <Redirect to='/MyAccount' />
            } else {
                return (
                    <div>
                        <Typography variant="h6" gutterBottom>
                            Pronunciation
                    </Typography>
                        <div className="form-row">
                            <div className="col-auto my-1">
                                <SelectList
                                    handleChangeStudyList={this.handleChangeStudyList.bind(this)}
                                    vocabularysetList={this.state.vocabularysetList}
                                    handleWordsList={this.handleWordsList.bind(this)}
                                    biblicalLiteraryUnits={this.state.biblicalLiteraryUnits}
                                    handleBiblicalList={this.handleBiblicalList.bind(this)}
                                />
                            </div>

                        </div>
                        <Card ref={this.cardChild}
                            wordsList={this.state.cardwordsList}
                            defaultText={this.state.defaultText}
                            defaultDefinition={this.state.defaultDefinition}
                            pathaudioFile={this.state.audioUrl}
                            groupName={this.state.groupName}
                            vocabularySetId={this.state.vocabularySetId}
                            fontSize={this.state.fontSize}
                            flipCard={this.state.flipCard}
                            textPropertyName={this.state.textPropertyName}
                            definitionPropertyName={this.state.definitionPropertyName}
                            //added due to 3/18/21 request
                            displayShuffleButton={true}
                            translationBox={false}
                            wordIdPropertyName={this.state.wordPropertyId}
                        />
                    </div>
                );
            }
        }
    }
}
