import { BehaviorSubject } from 'rxjs';
import { config } from '../components/Config.custom'
import { sessionTools } from './sessionTools';
//import { handleResponse } from '@/_helpers';
const currentUserSubject = new BehaviorSubject(sessionTools.getCookie('btu_'));

export const UserService = {
    usernameValidation,
    emailValidation,
    getUserInformation,
    updateAccount,
    isSubscriptionExpired,
    ResetPassword,
    
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function getUserInformation() {
    return new Promise(function (resolve, reject) {
        if (!currentUserSubject) return reject(null);
        var loggedUser = sessionTools.getCookie('btu_');
        var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': bearer,
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };
        fetch(config.api_cwo + `User/${loggedUser.guid}`, requestOptions)
            .then(res => (res.json()))
            .then(res => {
                const { currentUser } = res;
                return resolve(currentUser)
            })
    });
}
function isSubscriptionExpired(setExpiration) {
    var loggedUser = sessionTools.getCookie('btu_');
    if (!loggedUser) return setExpiration(true);
    
    var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': bearer,
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    fetch(config.api_cwo + `User/GetSubscription?id=${loggedUser.guid}`, requestOptions)
        .then(res => (res.json()))
        .then(res => {
            const { expired } = res;
            if (expired === true) {
                setExpiration(true)
            } else {
                setExpiration(false)
            }
        });
}
function usernameValidation(username) {
    return new Promise(function (resolve, reject) {
        /*stuff using username, password*/
        if (!username) return resolve(true);
        if (username.length >= 6) {
            var bearer = 'Bearer ' + sessionTools.getCookie('bt_', true);
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': bearer,
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            };
            fetch(config.api_cwo + `User/UsernameValidation?username=${username}`, requestOptions)
                .then(res => (res.json()))
                .then(res => {
                    const { exist } = res;
                    resolve(!exist)
                })
        }
    });
}
function emailValidation(email, update) {
    return new Promise(function (resolve, reject) {
        /*stuff using username, password*/
        if (!email) return resolve(true);
        if (email.length >= 6) {
            //var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
            var guid = '';
            if (update) {
                var loggedUser = sessionTools.getCookie('btu_');
                guid = loggedUser.guid;
            }
            var bearer = 'Bearer ' + sessionTools.getCookie('bt_', true);
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': bearer,
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            };
            fetch(config.api_cwo + `User/EmailValidation?email=${email}&guid=${guid}`, requestOptions)
                .then(res => (res.json()))
                .then(res => {
                    const { exist } = res;
                    resolve(!exist)
                })
        }
    });
}

function updateAccount(values, finalizeSaving) {
    if (!currentUserSubject) return (null);
    var loggedUser = sessionTools.getCookie('btu_');
    var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
    values.country = parseInt(values.country);
    values.guid = loggedUser.guid;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': bearer,
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(values)
    };
    fetch(config.api_cwo + 'User/UpdateUser', requestOptions)
        .then(res => (res.json()))
        .then(
            (result) => {
                if (result.result !== "0") {
                    finalizeSaving(false);
                } else {
                    sessionTools.setCookie('btu_', result);
                    sessionTools.setCookie('bt_', result.token);
                    currentUserSubject.next(JSON.stringify(result));
                    finalizeSaving(true);
                }
                return (result)
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                finalizeSaving(false);
                return (error);
            }
        )
}

function ResetPassword(email, setSubmitting, setOpenValidation, setAlertMessage) {
    var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
    fetch(config.api_cwo + `User/ResetPassword?inputResetPasswordEmail=${email}`, {
        method: 'POST',
        headers: {
            'Authorization': bearer,
            'Accept': 'application/json'
        }
    }).then(res => res.json())
        .then(
            (result) => {
                //console.log(result);
                if (result.result === "0") {
                    setSubmitting(true);
                    setOpenValidation(true);
                    setAlertMessage('Your password has been reset. Please check your email.');
                    
                } else {
                    if (result.validation) {
                        setOpenValidation(true);
                        setAlertMessage(result.validation);
                    }
                }
                setSubmitting(false);
            },
            (error) => {
                console.log(error)
                return [];
            }
        ).catch(error => { console.log(error); return []; });
}