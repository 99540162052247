import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        textAlign: 'justify',
        color: theme.palette.text.secondary,
    },
    RadioGroup: {
        paddingRight: theme.spacing(2),
        height:190
    },    
    control: {
        padding: theme.spacing(0),
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1),      
    },
    button: {
        margin: theme.spacing(1, 1, 0, 7),
    },
    labelCorrectAnswer: {
        color: "#239B56",        
    },
    labelWrongAnswer: {
        color: "#E74C3C",        
    },
    labelHelper: {   
        bottom:0
    }
}));

function CheckAnswer(props) {
    const classes = useStyles();
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }    
    if (props.display === true && (props.wordsList)) {
        if(props.wordsList[props.field].toLocaleLowerCase() === "" && props.selectedOption === "N/A"){
            return (<div><CheckIcon className={classes.labelCorrectAnswer} /> N/A </div>);
        } 
        else if(props.wordsList[props.field].toLocaleLowerCase() === ""){
            return (<div><CheckIcon className={classes.labelWrongAnswer} /> N/A </div>);
        }
        else if (props.wordsList[props.field].toLocaleLowerCase() === props.selectedOption.toLocaleLowerCase()) {
            return (<div><CheckIcon className={classes.labelCorrectAnswer} /> {capitalizeFirstLetter(props.wordsList[props.field])}</div>);
        }
        else {
            return (<div><ClearIcon className={classes.labelWrongAnswer} /> {capitalizeFirstLetter(props.wordsList[props.field])}</div>);
        }
    } else {
        return (
            null
        );
    }
}


export default function ItemQuiz({ wordsList, currentCard, cardNum, noresults }) {
    const classes = useStyles();
    const [display, setDisplay] = React.useState(false);

    const [error1, setError1] = React.useState(false);
    const [error2, setError2] = React.useState(false);
    const [error3, setError3] = React.useState(false);
    const [error4, setError4] = React.useState(false);
    const [error5, setError5] = React.useState(false);

    const [valueGroup1, setValueGroup1] = React.useState('');
    const [valueGroup2, setValueGroup2] = React.useState('');
    const [valueGroup3, setValueGroup3] = React.useState('');
    const [valueGroup4, setValueGroup4] = React.useState('');
    const [valueGroup5, setValueGroup5] = React.useState('');

    


    const handleRadioChange1 = (event) => {
        try {
            setValueGroup1(event.target.value);
            if (display === true) {
                if (event.target.value.toLocaleLowerCase() === wordsList[currentCard].dvForm.toLocaleLowerCase()) {
                    setError1(false);
                } else {
                    setError1(true);
                }
            }
            else {
                setError1(false);
            }
        } catch (e) { }
    };
    const handleRadioChange2 = (event) => {
        try {
            setValueGroup2(event.target.value);
            if (display === true) {
                if (event.target.value.toLocaleLowerCase() === wordsList[currentCard].tenseForm.toLocaleLowerCase()) {
                    setError2(false);
                } else {
                    setError2(true);
                }
            }
            else {
                setError2(false);
            }
        } catch (e) { }
    };
    const handleRadioChange3 = (event) => {
        try {
            setValueGroup3(event.target.value);
            if (display === true) {
                if (event.target.value.toLocaleLowerCase() === wordsList[currentCard].personForm.toLocaleLowerCase() || (event.target.value === 'N/A' && wordsList[currentCard].personForm.toLocaleLowerCase() === "")) {
                    setError3(false);
                } else {
                    setError3(true);
                }
            }
            else {
                setError3(false);
            }
        } catch(e) { }
    };
    const handleRadioChange4 = (event) => {
        try {
            setValueGroup4(event.target.value);
            if (display === true) {
                if (event.target.value.toLocaleLowerCase() === wordsList[currentCard].numberForm.toLocaleLowerCase() || (event.target.value === 'N/A' && wordsList[currentCard].numberForm.toLocaleLowerCase() === "")) {
                    setError4(false);
                } else {
                    setError4(true);
                }
            }
            else {
                setError4(false);
            }
        } catch (e) { }
    };
    const handleRadioChange5 = (event) => {
        try {
            setValueGroup5(event.target.value);
            if (display === true) {
                if (event.target.value.toLocaleLowerCase() === wordsList[currentCard].genderForm.toLocaleLowerCase() || (event.target.value === 'N/A' && wordsList[currentCard].genderForm.toLocaleLowerCase() === "")) {
                    setError5(false);
                } else {
                    setError5(true);
                }
            }
            else {
                setError5(false);
            }
        } catch (e) { }
    };

    useEffect(() => {
    
        setDisplay(false);
        
        setError1(false);
        setError2(false);
        setError3(false);
        setError4(false);
        setError5(false);

        setValueGroup1('');
        setValueGroup2('');
        setValueGroup3('');
        setValueGroup4('');
        setValueGroup5('');
    }, [cardNum]);

   
    const handleSubmit = (event) => {
        event.preventDefault();
        setDisplay(true);
        try {
        if (noresults === false){
            //check the answers
            if (valueGroup1.toLocaleLowerCase() === wordsList[currentCard].dvForm.toLocaleLowerCase()) {
                setError1(false);
            } else {
                setError1(true);
            }
            if (valueGroup2.toLocaleLowerCase() === wordsList[currentCard].tenseForm.toLocaleLowerCase()) {
                setError2(false);
            } else {
                setError2(true);
            }
            if (valueGroup3.toLocaleLowerCase() === wordsList[currentCard].personForm.toLocaleLowerCase() || (valueGroup3 === 'N/A' && wordsList[currentCard].personForm.toLocaleLowerCase()==="")) {
                setError3(false);
            } else {
                setError3(true);
            }
            if (valueGroup4.toLocaleLowerCase() === wordsList[currentCard].numberForm.toLocaleLowerCase() || (valueGroup4 === 'N/A' && wordsList[currentCard].numberForm.toLocaleLowerCase()==="")) {
                setError4(false);
            } else {
                setError4(true);
            }
            if (valueGroup5.toLocaleLowerCase() === wordsList[currentCard].genderForm.toLocaleLowerCase() || (valueGroup5 === 'N/A' && wordsList[currentCard].genderForm.toLocaleLowerCase()==="")) {
                setError5(false);
            } else {
                setError5(true);
            }
            event.target.reset();
        }
    } catch (e) { }
        
    };

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={0} >
                    <form onSubmit={handleSubmit} id={"inlineQuizForm"} >
                        <FormControl component="fieldset" error={error1} >
                            <FormLabel component="legend">Derived Verb</FormLabel>
                            <RadioGroup aria-label="quiz" name="quiz" value={valueGroup1} onChange={handleRadioChange1} className={classes.RadioGroup}>
                                <FormControlLabel className={classes.formControl} value="Qal" control={<Radio />} label="Qal" />
                                <FormControlLabel className={classes.formControl} value="Niphal" control={<Radio />} label="Niphal" />
                                <FormControlLabel className={classes.formControl} value="Piel" control={<Radio />} label="Piel" />
                                <FormControlLabel className={classes.formControl} value="Pual" control={<Radio />} label="Pual" />
                                <FormControlLabel className={classes.formControl} value="Hiphil" control={<Radio />} label="Hiphil" />
                                <FormControlLabel className={classes.formControl} value="Hophal" control={<Radio />} label="Hophal" />
                                <FormControlLabel className={classes.formControl} value="Hithpael" control={<Radio />} label="Hithpael" />
                            </RadioGroup>
                            <FormLabel component="label" error={false} ><CheckAnswer display={display} wordsList={wordsList[currentCard]} selectedOption={valueGroup1} field={"dvForm"} /></FormLabel>
                        </FormControl>
                        <FormControl component="fieldset" error={error2} >
                            <FormLabel component="legend">Tense</FormLabel>
                            <RadioGroup aria-label="quiz" name="quiz" value={valueGroup2} onChange={handleRadioChange2} className={classes.RadioGroup}>
                                <FormControlLabel className={classes.formControl} value="Perfect" control={<Radio />} label="Perfect" />
                                <FormControlLabel className={classes.formControl} value="Imperfect" control={<Radio />} label="Imperfect" />
                                <FormControlLabel className={classes.formControl} value="Imperative" control={<Radio />} label="Imperative" />
                                <FormControlLabel className={classes.formControl} value="Participle" control={<Radio />} label="Participle" />
                                <FormControlLabel className={classes.formControl} value="Infinitive" control={<Radio />} label="Infinitive" />
                            </RadioGroup>
                            <FormLabel component="label" error={false} ><CheckAnswer display={display} wordsList={wordsList[currentCard]} selectedOption={valueGroup2} field={"tenseForm"} /></FormLabel>
                        </FormControl>
                        <FormControl component="fieldset" error={error3} >
                            <FormLabel component="legend">Person</FormLabel>
                            <RadioGroup aria-label="quiz" name="quiz" value={valueGroup3} onChange={handleRadioChange3} className={classes.RadioGroup}>
                                <FormControlLabel className={classes.formControl} value="First" control={<Radio />} label="First" />
                                <FormControlLabel className={classes.formControl} value="Second" control={<Radio />} label="Second" />
                                <FormControlLabel className={classes.formControl} value="Third" control={<Radio />} label="Third" />
                                <FormControlLabel className={classes.formControl} value="N/A" control={<Radio />} label="N/A" />
                            </RadioGroup>
                            <FormLabel component="label" error={false} className={classes.labelHelper} ><CheckAnswer display={display} wordsList={wordsList[currentCard]} selectedOption={valueGroup3} field={"personForm"} /></FormLabel>
                        </FormControl>
                        <FormControl component="fieldset" error={error4} >
                            <FormLabel component="legend">Number</FormLabel>
                            <RadioGroup aria-label="quiz" name="quiz" value={valueGroup4} onChange={handleRadioChange4} className={classes.RadioGroup}>
                                <FormControlLabel className={classes.formControl} value="Singular" control={<Radio />} label="Singular" />
                                <FormControlLabel className={classes.formControl} value="Plural" control={<Radio />} label="Plural" />
                                <FormControlLabel className={classes.formControl} value="N/A" control={<Radio />} label="N/A" />
                            </RadioGroup>
                            <FormLabel component="label" error={false} ><CheckAnswer display={display} wordsList={wordsList[currentCard]} selectedOption={valueGroup4} field={"numberForm"} /></FormLabel>
                        </FormControl>
                        <FormControl component="fieldset" error={error5} >
                            <FormLabel component="legend">Gender</FormLabel>
                            <RadioGroup aria-label="quiz" name="quiz" value={valueGroup5} onChange={handleRadioChange5} className={classes.RadioGroup}>
                                <FormControlLabel className={classes.formControl} value="Masculine" control={<Radio />} label="Masculine" />
                                <FormControlLabel className={classes.formControl} value="Feminine" control={<Radio />} label="Feminine" />
                                <FormControlLabel className={classes.formControl} value="Common" control={<Radio />} label="Common" />
                                <FormControlLabel className={classes.formControl} value="N/A" control={<Radio />} label="N/A" />
                            </RadioGroup>
                            <FormLabel component="label" error={false} ><CheckAnswer display={display} wordsList={wordsList[currentCard]} selectedOption={valueGroup5} field={"genderForm"} /></FormLabel>
                        </FormControl>
                        <FormControl component="fieldset" >
                            <Button type="submit" variant="outlined" color="primary" className={classes.button}>
                                Check Answer
                            </Button>
                        </FormControl>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}


// React component for the card (main component)
export class Quiz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    async componentDidMount() {
        //this.state.text = this.props.defaultText;

    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.wordsList[this.props.currentCard] !== prevProps.wordsList[prevProps.currentCard] &&  prevProps.wordsList[prevProps.currentCard] !== '') {

        }        
    }
    render() {
        return (
            <ItemQuiz wordsList={this.props.wordsList} currentCard={this.props.currentCard} cardNum={this.props.cardNum} noresults={this.props.noresults}/>
        );
    }
}