import React, { useEffect, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { config } from './Config.custom'
import { creativeworksStore } from '../_services/creativeworksStore'
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ValidationMessage from './ValidationMessage'
import { sessionTools } from '../_services/sessionTools'

//const AuthenticatePublicRequest = {
//    Username: process.env.REACT_APP_CLIENT_ID,
//    Password: process.env.REACT_APP_KEY
//};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));


export default function CashNetForm(props) {
    const formRef = useRef(null);
    const { formValues, casUser, cashNetConfiguration } = props;
    const { email, country, state, subscription, guid, firstName, lastName } = formValues;
    const [GLCode, setGLCode] = React.useState('');
    const [itemDescription, setitemDescription] = React.useState('');
    const [itemPrice, setitemPrice] = React.useState('');
    //const [cashnetConfiguration, setCashNetConfiguration] = React.useState([]);
    const [gotoCashNet, setgoto] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [showWarningWrong, setshowWarningWrong] = React.useState(false);

    useEffect(() => {
        //
        const getGLCode = () => {
            var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
            fetch(config.api_cwo + 'GLCode?itemName=BiblicalSubscription&countryid=' + country + '&statename=' + state + '&CasUser=' + casUser, {
                method: 'GET',
                headers: {
                    'Authorization': bearer,
                    'Accept': 'application/json'
                }
            }).then(res => res.json())
                .then(
                    (result) => {
                        if (result.result === "0") {
                            setGLCode(result.gl);
                            //console.log(result.gl)
                        } else {
                            setGLCode('00000000000000000');
                        }
                    },
                    (error) => {
                        console.log(error)
                        setGLCode('00000000000000000');
                    }
                ).catch(error => { console.log(error); return []; });
        };

        const setgotoCashNet = (value) => {
            if (value) {
                setgoto(true);
                formRef.current.submit();
            } else {
                setLoading(false);
                props.setOpenCashNet(false);
            }
        }

        getGLCode();
        //setCashNetConfiguration(creativeworksStore.getCashNetConfiguration(true, setgoto));
        creativeworksStore.resubscribe(formValues, casUser, setgotoCashNet, setshowWarningWrong);

        var subs = JSON.parse(sessionTools.getCookie('Subscription'));
        subs.map(function (elm) {
            if (elm['Months'] === subscription) {
                if (casUser === true) {
                    if (elm['Student'] === 1) {
                        setitemDescription(elm['Name'])
                        setitemPrice(elm['Price'])
                    }
                }
                else {
                    if (elm['Student'] === 0) {
                        setitemDescription(elm['Name'])
                        setitemPrice(elm['Price'])
                    }
                }
                
            }
            return true;
        });

    }, [formValues, subscription, props, country, state]);

    


    //console.log('Cashnetform is being used')
    return (<React.Fragment>

        <ValidationMessage open={showWarningWrong} 
        setshowWarningWrong={setshowWarningWrong} 
        message="Ups, something went wrong. Please, try again." 
        severity="warning" />

        <CssBaseline />
        {(!gotoCashNet) ? (
            <Backdrop className={classes.backdrop} open={loading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        ) : (
                <main>
                    <form ref={formRef} id="CashNetForm" method="post" action={cashNetConfiguration.actionURLTraining} name="CASHNetForm"> //This needs to change when you are publishing to training/production
                        <input type='hidden' name='NAME_G' value={`${firstName} ${lastName}`} />
                        <input type="hidden" name="custcode" value={`${guid}`} />
                        <input type="hidden" name="email" value={`${email}`} />
                        <input type="hidden" name="itemcode1" value={cashNetConfiguration.cashNetItemCode} />
                        <input type="hidden" name="amount1" value={itemPrice} />
                        <input type="hidden" name="qty1" value="1" />
                        <input type="hidden" name="desc1" value={itemDescription} />
                        <input type="hidden" name="ref1type1" value="GL" />
                        <input type="hidden" name="ref1val1" value={GLCode} />
                        <input type="hidden" name="itemcnt" value="1" />
                        <input type="hidden" name="signouturl" value={cashNetConfiguration.signouturl} />
                        <input type="hidden" name="incompletesignouturl" value={cashNetConfiguration.incompletesignouturl} />
                    </form>
                </main>
            )}
    </React.Fragment>
    );
};