import { config } from '../components/Config.custom'
import jwt from 'jsonwebtoken'
import { sessionTools } from '../_services/sessionTools'


const AuthenticatePublicRequest = {
    Username: process.env.REACT_APP_CLIENT_ID,
    Password: process.env.REACT_APP_KEY/*,
Token:''*/
};


export const Connector = {
    serverValidationToken,
    verifyToken,
    resetToken
};


function serverValidationToken() {
    // Subscribe to changes   
    try {
        //var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
        const requestOptions = {
            method: 'POST',
            headers: {
                //'Authorization': bearer,
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({ username: AuthenticatePublicRequest.Username, password: AuthenticatePublicRequest.Password })
        };
        fetch(config.api_cwo + 'Authorization', requestOptions)
            .then(res => (res.json()))
            .then(
                (result) => {
                    sessionTools.setCookie('bt_', result.token);
                    //return result.token;
                //if (response.status === 401) {
                //    authenticationService.logout();
                //    return ("Unauthorized")
                //}
                //else if (response.status !== 200) {
                //    //throw new Error(response.status)                        
                //    return (response.status)
                //} else {
                //    if (response) {
                //        if (JSON.parse(response).tokenStatus === 'Valid') {
                //            return ("Valid")
                //        } else {
                //            return ("NoValid")
                //        }
                //    }
                //}
            })
            .catch(function (error) {
                ///if status code 401. Need help here
                console.log(error);
            });
    } catch (e) {
        console.log(e);
    }

}


function verifyToken() {
    const _token = sessionTools.getCookie('bt_');
    if (_token) {
        console.log('verifytoken')
        var isExpired = false;
        var decodedToken = jwt.decode(_token, { complete: true });
        var dateNow = new Date();
        if (decodedToken.payload.exp * 1000 < dateNow.getTime()) {
            isExpired = true;
        }
        return isExpired;
    }
    else
        return true;
}

function resetToken(resetUser = true) {
    var tokenCookie = sessionTools.getCookie('bt_');
    if (typeof tokenCookie === 'undefined' || tokenCookie === null || JSON.stringify(tokenCookie) === '{}') {
        tokenCookie = serverValidationToken();
    }else {
        sessionTools.setCookie('bt_', tokenCookie);
    }
    if (resetUser) {
    var userCookie = sessionTools.getCookie('btu_');
        if (typeof userCookie !== 'undefined' && userCookie !== null && JSON.stringify(userCookie) !== '{}') {
            sessionTools.setCookie('btu_', userCookie);
        }
        else {
            sessionTools.removeCookie('btu_');
            }
    }
}
