import React, { Component } from 'react';
import { Card } from './CardContainer';

import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom'
import { sessionTools } from '../_services/sessionTools';
import { config } from './Config.custom';

//for old shuffle button
/* export function ShuffleButton({ shuffleChange }) {
    function handleClick(e) {
        e.preventDefault();
        shuffleChange(e);
    }
    return (
        <div className="text-right">
            <Tooltip title="Shuffle list">
                <Button onClick={handleClick}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<ShuffleIcon />} >
                    Shuffle
            </Button>
            </Tooltip>
        </div>
    );
} */

export class Vocabulary extends Component {
    static displayName = Vocabulary.name;

    constructor(props) {
        super(props);
        this.cardChild = React.createRef()
        this.awsPathAudio = 'https://creativeworks-store.s3-us-west-2.amazonaws.com/Material/BiblicalHebrew/audio';
        this.state = {
            vocabularySetId: 0,
            vocabularysetList: [],
            vocabularywordsList: [],
            ready: false,
            setListReady: false,
            defaultText: '',
            defaultDefinition: '',
            groupName: 'vocab',
            audioUrl: this.awsPathAudio,
            fontSize: 70,
            currentUser: sessionTools.getCookie('btu_'),
            textPropertyName: 'text',
            definitionPropertyName: 'definition',
        };
        this.getVocabularySetList = this.getVocabularySetList.bind(this);
        this.getVocabularyWordsList = this.getVocabularyWordsList.bind(this);

    }
    componentDidMount() {
        this.getVocabularySetList();
        //console.log(config.api_url);
    }
    hideError() {
        this.setState({ showError: !this.state.showError });
    }
    getVocabularySetList() {
        try {
            fetch(config.api_url + 'VocabularySets')
                .then(res => res.json())
                .then(
                    (result) => {
                        localStorage.removeItem('hebrew_VocabularyStudyList');
                        //var result = result;
                        this.setState({
                            vocabularysetList: result,
                            setListReady: true,
                            isLoading: false
                        });
                        var _firstList = result[0];
                        this.getVocabularyWordsList(_firstList.id);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);
                    }
                )
        } catch (e) {
            console.info(e);
        }
        

    }
    getVocabularyWordsList(setId) {
        try {
            fetch(config.api_url + 'VocabularyWords')
                .then(res => res.json())
                .then(
                    (result) => {
                        localStorage.removeItem('hebrew_VocabularySets')
                        var data = result;
                        const resultpart = data.filter(d => d.vocabularySetsId === parseInt(setId));
                        if (resultpart) {
                            this.setState({
                                ready: true,
                                vocabularywordsList: resultpart,
                                groupName: 'vocab' + ("0" + setId).slice(-2) + '-',
                                vocabularySetId: setId,
                                defaultText: resultpart[0].text,
                                defaultDefinition: resultpart[0].definition,
                                audioUrl: this.awsPathAudio + '/vocabulary/' + ("0" + setId).slice(-2) + '/'
                            }, () => {
                                //this.cardChild.current.NewListSelected();
                            });
                        }
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);
                    }
                )
        } catch (e) {
            console.info(e);
        }
    }
    handleChange = (event) => {
        this.setState({ vocabularySetId: event.target.value });
        this.getVocabularyWordsList(event.target.value);
    };
    //Old Shuffle List button
/*     onShuffleList() {               
        try {            
            fetch(config.api_url + `VocabularyWords/GetRandom?id=${this.state.vocabularySetId}`)
                .then(res => res.json())
                .then(
                    (result) => {                       
                        this.setState({                            
                            ready: true,
                            vocabularywordsList: result,
                            //groupName: 'vocab' + ("0" + setId).slice(-2) + '-',
                            //vocabularySetId: setId,
                            defaultText: result[0].text,
                            defaultDefinition: result[0].definition,
                            //audioUrl: this.awsPathAudio + '/vocabulary/' + ("0" + setId).slice(-2) + '/'
                        }, () => {                                
                                //this.cardChild.current.NewListSelected();
                        });                                        
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);                      
                    }
                )
        } catch (e) {
            console.info(e);
        }
    }*/
    
    render() {
        //console.log(this.state.authenticate);
        if (this.state.currentUser == null) {
            return <Redirect to='/Sign-in' />
        }
        else {
            var subscription = this.state.currentUser.biblicalSubscription
            if (subscription === false) {
                //console.log(this.state.currentUser)
                return <Redirect to='/MyAccount' />
            }
            else {
                return (
                    <div>
                        <Typography variant="h6" gutterBottom>
                            Vocabulary
                    </Typography>
                        <div className="form-row">
                            <div className="col-auto my-1">
                                <label className="mr-sm-2" htmlFor="vocabularysetList"><strong>Select Study List...</strong></label>
                                <select onChange={this.handleChange} className="form-control" id="vocabularysetList">
                                    {
                                        this.state.vocabularysetList.map(data =>
                                            <option key={data.id} value={data.id}>{data.title}</option>)
                                    }
                                </select>
                            </div>
                            {/*                     <div class="col-sm text-right">
                            <ShuffleButton shuffleChange={this.onShuffleList.bind(this)} />
                        </div>
    */}                </div>
                        <Card ref={this.cardChild}
                            wordsList={this.state.vocabularywordsList}
                            defaultText={this.state.defaultText}
                            defaultDefinition={this.state.defaultDefinition}
                            pathaudioFile={this.state.audioUrl}
                            vocabularySetId={this.state.vocabularySetId}
                            groupName={this.state.groupName}
                            fontSize={this.state.fontSize}
                            flipCard={false}
                            textPropertyName={this.state.textPropertyName}
                            definitionPropertyName={this.state.definitionPropertyName}
                            wordIdPropertyName='wordId'
                            displayShuffleButton={true}
                            translationBox={false}
                            displayExplanationIcon={true}
                            explanationIconText='Translate'
                            explanationIconSubText='Show Translation'
                        />
                    </div>
                );
            }
        }
    }
}
