import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { authenticationService } from '../_services/authenticationService'
import { connectAdvanced } from 'react-redux';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            currentUser: null,
            currentSubscription: null,
            subsExpired: false,
            analyticKeysUrl: '',
            vocabularyFrequencyUrl: '',
            advancedSubscription: false
        };
        this.setExpiration = this.setExpiration.bind(this);
    }
    componentDidMount() {
        //UserService.isSubscriptionExpired(this.setExpiration);
        authenticationService.currentUser.subscribe(x => {
            if (x != null) {
                try {
                    this.setState({ currentUser: x, currentSubscription: JSON.parse(x).biblicalSubscription });
                }
                catch (e) {
                    this.setState({ currentUser: x, currentSubscription: x.biblicalSubscription });
                }
                if (x.username == 'devtam') {
                    this.setState({
                        advancedSubscription: true
                    })
                }
            }
        });
        var token = authenticationService.GetToken();
        this.setState({
            analyticKeysUrl: "https://hebrew.byu.edu/bible?userId=" + token,
            vocabularyFrequencyUrl: "https://hebrew.byu.edu/frequency?userId=" + token
        })
       
    }
    setExpiration(value) {
        this.setState({ subsExpired: value, loading: false });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    
    render() {
        const { currentUser } = this.state;
        const { currentSubscription } = this.state;
        const { analyticKeysUrl } = this.state;
        const { vocabularyFrequencyUrl } = this.state;
        const { advancedSubscription } = this.state;
        //console.log('NavMenu58')
        //console.log(currentUser)
        //console.log(currentSubscription)

        return (
            <byu-menu slot="nav" active-selector=".is-active" className="navbar-body">
                <Link to="/"> Home </Link>
                <Link to="/About">About</Link>
                {currentSubscription &&
                    <Link to="/Components">Components</Link>
                }
                {advancedSubscription &&
                    <div class="dropdown">
                        <button class="dropdown-btn">    Advanced Modules</button>
                        <div class="dropdown-child">
                            <a class="dropdown-item" href={analyticKeysUrl}>Hebrew Bible with Analytical Keys</a>
                        <a class="dropdown-item" href={vocabularyFrequencyUrl}>Vocabulary by Frequency</a>
                        </div>
                    </div>
                }
                {/*<p class="" href={analyticKeysUrl}>Hebrew Bible with Analytic Keys</p>
                {currentSubscription &&
                    <a href={vocabularyFrequencyUrl}>Vocabulary by Frequency</a>
                }*/}
                {currentUser &&
                    <Link to="/MyAccount">My Account</Link>
                }               
            </byu-menu>           
        );
    }
}
