import React, { Component } from 'react';
import { authenticationService } from '../_services/authenticationService'
import { UserService } from '../_services/userService'
import myAccountFormModel from './myAccount/formModel';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Container from "@material-ui/core/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";


const validationSchema = yup.object({
    email: yup.string("Enter your Email").required("Email is required")
});

const useStyles = makeStyles((theme) => ({
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(400 + theme.spacing(2) * 2)]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    Paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        //marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function ResetPasswordForm(props) {
    const classes = useStyles();
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [openValidation, setOpenValidation] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(false);
    //const [message, setMessage] = React.useState('');
    //const [severity, setSeverety] = React.useState('');
    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: validationSchema,

        onSubmit: (values) => {
            setSubmitting(true);
            UserService.ResetPassword(
                values.email,
                setSubmitting,
                setOpenValidation,
                setAlertMessage
            );
            //showMessage(setAlertMessage)
        },
    });
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenValidation(false);
    };

    const showMessage = (result) => {
        if (!result) {
            //setMessage("Your password has been reset.");
            //setSeverety("success")
            setAlertMessage(true);
        } else {
            //setMessage("Oops, something went wrong. Please, try again.");
            //setSeverety("warning")
            setAlertMessage(true);
        }
        //setSaving(false);
    }

    const _handleSubmit = (values, actions) => {
        //setSaving(true);
        UserService.ResetPassword(values, showMessage);
        actions.setTouched({});
        actions.setSubmitting(false);

    }

    if (props.loading) {
        return (
            <React.Fragment>
                <Backdrop className={classes.backdrop} open={props.loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <CssBaseline />
            </React.Fragment>
        );
    } else {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Paper elevation={3} className={classes.layout}>
                    <div className={classes.Paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Reset Password
                        </Typography>
                        <form onSubmit={formik.handleSubmit} className={classes.form}>
                            <Backdrop className={classes.backdrop} open={isSubmitting}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                name="email"
                                label="email"
                                autoFocus
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.email && Boolean(formik.errors.email)
                                }
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onSubmit={_handleSubmit}
                            >
                                Email Reset Password
              </Button>

                            <Snackbar
                                open={openValidation}
                                autoHideDuration={6000}
                                onClose={handleClose}
                            >
                                <Alert onClose={handleClose} severity='warning'>
                                    {alertMessage}
                                </Alert>
                            </Snackbar>
                        </form>
                    </div>
                </Paper>
            </Container>
        );
    }


}

export class ResetPassword extends Component {
    //static displayName = MyAccount.name;

    constructor(props) {
        super(props);
        this.state =
        {
            authenticate: authenticationService.isAuthenticate(), //by  default
            loading: true,
            formField: myAccountFormModel,
        };
        this.getData = this.getData.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount() {
        if (this.state.authenticate)
            this.getData();
    }


    handleClose = (value) => {
        this.setState({ alertMessage: value });
    }

    render() {
        return (
            <div className="container">
                <div className="row m-3 rounded border shadow">
                    <ResetPassword loading={this.state.loading} />
                </div>
            </div>
        );
    }
}
