import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { config } from "../Config.custom";
import SelectField from "../SignUp/FormModel/SelectField";
import { sessionTools } from "../../_services/sessionTools";
import { autenticationService, authenticationService } from "../../_services/authenticationService";
import ReCAPTCHA from "react-google-recaptcha";


const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  subslabel: {
    width: 300,
  },
  info: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

export default function Review(props) {
  const classes = useStyles();
  const [items, setItems] = React.useState([]);
  const {
      formField: { subscription },
      setCaptchaVerified
  } = props;

  useEffect(() => {
    var bearer = "Bearer " + sessionTools.getCookie("bt_");
    fetch(config.api_cwo + "Subscription?itemName=BiblicalSubscription", {
      method: "GET",
      headers: {
        Authorization: bearer,
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.items) {
            var items = JSON.parse(result.items);
            var resultArray;
            sessionTools.setCookie("Subscription", result.items);
              if (props.casUser === true) {
                  resultArray = items.filter(function (user) {
                      return user.Student === 1;
                  }).map(function (elm) {
                          return {
                              value: elm["Months"],
                              label: elm["Name"] + " - $" + elm["Price"],
                          };
                  })
              }
            else {
                  resultArray = items.filter(function (user) {
                      return user.Student === 0;
                  }).map(function (elm) {
                      return {
                          value: elm["Months"],
                          label: elm["Name"] + " - $" + elm["Price"],
                      };
              });
            }
            setItems(resultArray);
          }
        },
        (error) => {
          console.log(error);
          return [];
        }
      )
      .catch((error) => {
        console.log(error);
        return [];
      });
  }, []);


    function onChange(value) {
        //console.log('howdy', value)
        //console.log(props)
        //setCaptchaVerified(true);

        authenticationService.VerifyCaptcha(value, setCaptchaVerified)
    }
    function myChange(value) {
       
    }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Choose Subscription
      </Typography>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <SelectField
            name={subscription.name}
            label={subscription.label}
            data={items}
            fullWidth
            className={classes.subslabel}
            disable={true}
            myChange={myChange}
                  />
                  <br/>
                  <ReCAPTCHA
                      sitekey={config.captcha_site}
                      onChange={onChange}
                    />
        </FormControl>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider variant="middle" className={classes.info} />
          {/* <p>For information about purchasing a subscription, please contact <a href="mailto:catalog@byu.edu">catalog@byu.edu</a> or go to our
                                <a href="https://creativeworks.byu.edu/CreativeworksStore/ContactForm?"> Contact page</a> </p> 
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}
