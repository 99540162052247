import React, { Component,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Divider from '@material-ui/core/Divider';
import SignInButton from './SignInButton';
import { sessionTools } from '../_services/sessionTools';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: '100%',
        backgroundColor: '#cee3f5',
        borderColor: '#cee3f5',
        color: '#002e5d',
        textAlign: "center"

    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default function CenterMessage(props) {
    const classes = useStyles();
    let bodyMessage;
    let button=props.subtext;
    if (props.success) {
        button = <SignInButton></SignInButton>;
        bodyMessage = <strong> {props.transactionNumber} </strong>;
    } else {
        button = <SignInButton />;
        //bodyMessage = props.subtext;
    }
    useEffect(() => {
      
      }, []);
    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="h5" component="div">
                                    {props.message}
                                </Typography>
                                <Typography variant="body2" >
                                    {props.subtext} {bodyMessage}
                                </Typography>
                            </Grid>
                            <Divider variant="middle" />
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
                                    {button}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}


export class PaymentResponse extends Component {
    static displayName = PaymentResponse.name;

    constructor(props) {
        super(props);
        this.state = {
            success: false,
            transactionNumber: this.props.match.params.id,
            message: '',
            subtext: ''
        };
    }
    componentDidMount() {
        //const params = new URLSearchParams(this.props.location.search);
        //var data = params.get('data');
        //var uri_dec = decodeURIComponent(data);
        //if (uri_dec) {
        //    //const _json = JSON.parse(uri_dec);
        //}        
        if (this.state.transactionNumber === '0') {
            this.setState({
                message: 'Something went wrong.',
                success: false,
                subtext: 'Please, go back to checkout so you can try again.'
            });            
        } else {
            sessionTools.removeCookie('chekoutForm')
            this.setState({
                message: 'Your transaction was completed successfully.', success: true,
                subtext: 'You will receive a confirmation email from our credit card processing company shortly. Your transaction number is '
            });
        }       
    }

    render() {
        return (<CenterMessage transactionNumber={this.state.transactionNumber}
            message={this.state.message}
            subtext={this.state.subtext}
            success={this.state.success}
        />
        );
    }
}
