import Cookies from 'js-cookie'

//const cookies = new Cookies();
const options = {
    'expires': 0.0417,
    //'path': '/',
    //'domain': config.domain
}


export const sessionTools = {
    getCookie,
    setCookie,
    removeCookie
};

function getCookie(key, reload = false) {
    try {
        let cookie = Cookies.get(key);
        if (!cookie) {
            if (reload) {
                window.setInterval(window.location.reload(false), 200000);
                return cookie;
            }
            else {
                return cookie;
            }
        }
        //console.log('getCookie - ', key, ': ', cookie);
        return JSON.parse(cookie);
    }
    catch (error) {
        //console.log(key);
        //console.log(error);
        window.setInterval(window.location.reload(false), 200000);
        return error;
    }
}

function setCookie(key, value, option = options) {
    try {
        Cookies.set(key, JSON.stringify(value), option);
        //console.log('setCookie - ', key, ': ', JSON.stringify(value));
        //getCookie(key);
        return ('Success')
    }
    catch (error) {
        //console.log(error);
        return error;
    }
}

function removeCookie(key, option = options) {
    try {
        Cookies.remove(key, option);
        //console.log('removedCookie - ', key);
        return ('Success');
    }
    catch (error) {
        //console.log(error);
        return error;
    }
}