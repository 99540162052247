import React, { Component } from 'react';
import { Card } from './CardContainer';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { config } from './Config.custom'
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom'
import { sessionTools } from '../_services/sessionTools'


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function SelectList(props) {
    const classes = useStyles();
    const [typeList, setTypeList] = React.useState(1);
    const [levelList, setLevelList] = React.useState(1);

    const handleChangeType = (event) => {
        setTypeList(event.target.value);

        props.handleChangeTypeList(event.target.value, levelList);
    };
    const handleChangeLevel = (event) => {
        setLevelList(event.target.value);

        props.handleChangeLevelList(typeList, event.target.value);
    };


    return (
        <div className="form-row">
            <div className="col-auto my-1">
                <FormControl className={classes.formControl}>
                    <InputLabel id="select-grammartypes-label">Study list</InputLabel>
                    <Select
                        labelId="select-grammartypes-label"
                        id="select-grammartypes"
                        value={typeList}
                        onChange={handleChangeType} >
                        {
                            props.grammarTypeSetList.map(data =>
                                <MenuItem value={data.id} key={data.id}>{data.title}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </div>
            <div className="col-auto my-1">
                <FormControl className={classes.formControl}>
                    <InputLabel id="select-levelList-Label">Words list</InputLabel>
                    <Select labelId="select-levelList-Label"
                        id="select-levelList"
                        value={levelList}
                        onChange={handleChangeLevel} >
                        {
                            props.grammarLevelSetList.map(data =>
                                <MenuItem value={data.id} key={data.id}>{data.title}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

//For old shuffle button
/* export function ShuffleButton({ shuffleChange }) {
    function handleClick(e) {
        e.preventDefault();
        shuffleChange(e);
    }
    return (
        <div className="text-right">
            <Tooltip title="Shuffle list">
                <Button onClick={handleClick}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<ShuffleIcon />} >
                    Shuffle
            </Button>
            </Tooltip>
        </div>
    );
} */


export class Grammar extends Component {
    static displayName = Grammar.name;
    constructor(props) {
        super(props);
        this.cardChild = React.createRef()
        this.awsPathAudio = '';
        this.state = {
            grammarSetId: '',
            grammarTypeSetList: [],
            grammarLevelSetId: '',
            grammarLevelSetList: [],

            ready: false,
            selectedLeveId: '',
            selectedTypeId: '',
            currentCard: 0,
            cardwordsList: [],
            audioUrl: this.awsPathAudio,
            defaultText: null,
            defaultDefinition: null,
            groupName: '',
            fontSize: 48,
            flipCard: false,
            currentUser: sessionTools.getCookie('btu_'),
            textPropertyName: 'hebrewPhrase',
            definitionPropertyName: 'translation',

            myTranslation: '',
            translationError: false
        };
        //get data methods   
        this.getGrammarTypeSets = this.getGrammarTypeSets.bind(this);
        this.getGrammarLevelSets = this.getGrammarLevelSets.bind(this);
        this.getGrammarPhrases = this.getGrammarPhrases.bind(this);


        //Handle events
        this.handleChangeTypeList = this.handleChangeTypeList.bind(this);
        this.handleChangeLevelList = this.handleChangeLevelList.bind(this);
    }

    async componentDidMount() {
        this.getGrammarTypeSets();
        this.getGrammarLevelSets();
        this.getGrammarPhrases(1, 1);
    }

    hideError() {
        this.setState({ showError: !this.state.showError });
    }
    getGrammarTypeSets() {
        try {
            fetch(config.api_url + 'GrammarTypeSets')
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ready: true,
                            grammarTypeSetList: result
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            ready: false,
                            error
                        });
                    }
                )
        } catch (e) {
            console.info(e);
        }
    }
    getGrammarLevelSets() {
        try {
            fetch(config.api_url + 'GrammarTypeSets/GetLevelSet')
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ready: true,
                            grammarLevelSetList: result
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            ready: false,
                            error
                        });
                    }
                )
        } catch (e) {
            console.info(e);
        }
    }
    getGrammarPhrases(typeId, levelId, shuffle) {
        try {
            var _typeid = typeId;
            var _leveid = levelId;

            this.setState({
                selectedLeveId: _leveid,
                selectedTypeId: _typeid
            }, () => {
                if (!shuffle) shuffle = false;
                fetch(config.api_url + 'GrammarTypeSets/GetPhrases?typeId=' + _typeid + '&levelId=' + _leveid + '&shuffle=' + shuffle)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            //console.log (result);
                            this.setState({
                                ready: true,
                                cardwordsList: result,
                                defaultText: result[0].hebrewPhrase,
                                defaultDefinition: result[0].translation,
                                audioUrl: null,                                
                            }, () => {

                            });
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                ready: false,
                                error
                            });
                        }
                    )
            })



        } catch (e) {
            console.info(e);
        }
    }
    handleChangeTypeList(type, level) {
        //console.log('type:' + type + ' level: ' + level)
        this.getGrammarPhrases(type, level, false)
    }
    handleChangeLevelList(type, level) {
        //console.log('type:' + type + ' level: ' + level)
        this.getGrammarPhrases(type, level, false)
    }

    
/*     onShuffleList() {
        this.getGrammarPhrases(this.state.selectedTypeId
            , this.state.selectedLeveId
            , true)
    }
 */    
    onSetCurrentCard(index) {
        this.setState({ currentCard: index, helperText: '' });
    }
    render() {
        if (!this.state.currentUser) {
            return <Redirect to='/Sign-in' />
        }
        else {
            var subscription = this.state.currentUser.biblicalSubscription
            if (!subscription) {
                return <Redirect to='/MyAccount' />
            }
            else {
                return (
                    <div>
                        <Typography variant="h6" gutterBottom>
                            Grammar
                    </Typography>
                        <div className="form-row">
                            <div className="col-auto my-1">
                                <SelectList
                                    grammarTypeSetList={this.state.grammarTypeSetList}
                                    handleChangeTypeList={this.handleChangeTypeList.bind(this)}
                                    grammarLevelSetList={this.state.grammarLevelSetList}
                                    handleChangeLevelList={this.handleChangeLevelList.bind(this)}
                                />
                            </div>
                            {/*                    {<div class="col-sm text-right">
                            <ShuffleButton shuffleChange={this.onShuffleList.bind(this)} />
                        </div>} 
    */}                </div>

                        <Card ref={this.cardChild}
                            wordsList={this.state.cardwordsList}
                            defaultText={this.state.defaultText}
                            defaultDefinition={this.state.defaultDefinition}
                            pathaudioFile={this.state.audioUrl}
                            audioPlayer={false}
                            groupName={this.state.groupName}
                            vocabularySetId={this.state.vocabularySetId}
                            fontSize={this.state.fontSize}
                            flipCard={false}
                            displayExplanationIcon={true}
                            textPropertyName={this.state.textPropertyName}
                            definitionPropertyName={this.state.definitionPropertyName}
                            onSetCurrentCard={this.onSetCurrentCard.bind(this)}
                            displayShuffleButton={true}
                            translationBox={true}
                            explanationIconText='Translate'
                            explanationIconSubText='Show Translation'
                        />
                    </div>
                );
            }
        }
    }
}
