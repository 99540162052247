import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie'
import App from './App';
import registerServiceWorker from './registerServiceWorker';


import { Provider } from "react-redux";
import store from "./_store/index";


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl}>
            <CookiesProvider>
           <App />
        </CookiesProvider>
    </BrowserRouter>
   </Provider>,
  rootElement);

registerServiceWorker();

