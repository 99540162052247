import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { authenticationService } from '../_services/authenticationService';

export class Layout extends Component {
    static displayName = Layout.name;
    constructor(props) {
        super(props);
        this.state =
        {
            authenticated: authenticationService.isSubscribed(),
            subsExpired: false,
            loading: true
        };
    };

    render() {
        return (
            <div>
                <NavMenu />        
            <Container>
                {this.props.children}
            </Container>
            </div>
        );
  }
}
