import React, { Component } from 'react';
import SelectField from '../FormModel/SelectField'

export default class SelectFieldAPI extends Component {
    static displayName = SelectFieldAPI.name;
    constructor(props) {
        super(props);     
        this.state = {
            data: [],
            selectedValue:''
        };
        this.getData = this.getData.bind(this);
        this.passingvalue = this.passingvalue.bind(this);      
    }

    componentDidMount() {        
        if (this.props.name === 'country') {
            this.getData('country');
        }
        if (this.props.name === 'state') {
            this.getData('state');
        }
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
       
    }

    passingvalue(value) {         
        this.setState({selectedValue: value});
        if(this.props.handleChange)
            this.props.handleChange(value);
    }


    getData(method) {
        
        if (method === 'state') {          
            var data = JSON.parse(localStorage.getItem('states')).states
                .filter(function (entry) {
                return entry.countryId === 1;
            });
            var resultArray = data.map(function (elm) {
                return { value: elm['name'], label: elm['name'] };
            });

            this.setState({ data: resultArray });  
                     
        }
        else if (method === 'country') {           
            var data2 = JSON.parse(localStorage.getItem('countries'));
            var resultArray2 = data2.countries.map(function (elm) {
                return { value: elm['countryId'], label: elm['name'] };
            });
            this.setState({ data: resultArray2 });  
        }
    }
    
    render() {
        return (
            <SelectField           
                name={this.props.name}
                label={this.props.label}
                data={this.state.data}    
                myChange={this.props.myChange.bind(this)}
                disable={this.props.disabled}
                fullWidth                               
            />
        );
    }
}