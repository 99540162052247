import * as Yup from 'yup';
//import moment from 'moment';
import myAccountFormModel from '../SignUp/FormModel/checkoutFormModel';
import { UserService } from '../../_services/userService'

const {
    formField: {
        email,
        username,
        password,
        passwordRepeat,
        firstName,
        lastName,
        address1,
        city,
        state,
        country,
        zipcode,
        //subscription
    }
} = myAccountFormModel;

const emailRegEx = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;

function equalTo(ref, msg) {
	return this.test({
		name: 'equalTo',
		exclusive: false,
    message: msg, // || '${path} must be the same as ${reference}',
		params: {
			reference: ref.path
		},
		test: function(value) {
      return value === this.resolve(ref) 
		}
	})
};
Yup.addMethod(Yup.string, 'equalTo', equalTo);
var validationArray = [
    //step1
    Yup.object().shape({
        [username.name]: Yup.string()
            .required(`${username.requiredErrorMsg}`)
            .test(
                'len',
                `${username.requiredLengthErrorMsg}`,
                val => val && val.length >= 6
            ),
        [email.name]: Yup.string()
            .required(`${email.requiredErrorMsg}`)
            .matches(emailRegEx, email.requiredValidErrorMsg)
            .test("ValidateExist", `${email.requiredExistErrorMsg}`, function (value) { // Use function
                return UserService.emailValidation(value, true)
                    .then(function (uid) {
                        return (uid)
                    })
            })
            .test(
                'len',
                `${username.requiredLengthErrorMsg}`,
                val => val && val.length >= 6
            ),
        [password.name]: Yup.string()
            .nullable()
            .test(
                'len',
                `${password.requiredLengthErrorMsg}`,
                function (val) {
                    if (val) {
                        return val.length >= 6;
                    }
                    return true;
                }
            ),
        [passwordRepeat.name]: Yup.string()            
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .equalTo(Yup.ref('password')),          
        [firstName.name]: Yup.string().required(`${username.requiredErrorMsg}`),
        [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
        [zipcode.name]: Yup.string()
            .required(`${zipcode.requiredErrorMsg}`)
            .test(
                'len',
                `${zipcode.invalidErrorMsg}`,
                val => val && val.length >= 5
            ),
        [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
        [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
        [state.name]: Yup.string().required(`${state.requiredErrorMsg}`)
            .test(
                'US State',
                `${state.invalidStateMsg}`,
                (val, ctx) => {
                    var passedTest = false
                    if (ctx.parent.country == 1) {
                        JSON.parse(localStorage.getItem('states'))['states'].forEach(element => {
                            if (val == element.name) {
                                passedTest = true
                            }
                        })
                    }
                    else { passedTest = true }
                    return passedTest
                }),
        [country.name]: Yup.string().required(`${country.requiredErrorMsg}`)
    })
];

export default validationArray;