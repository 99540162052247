import React, { Component } from 'react';
import { Card } from './CardContainer';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { config } from './Config.custom'
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import { sessionTools } from '../_services/sessionTools';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    RadioGroup: {
        fontSize: '50%',
        margin: theme.spacing(0),
        display: 'flex',
        flexDirection: 'row'
    }
}));

export default function SelectList(props) {
    const classes = useStyles();
    const [biblicalList, setBiblicalList] = React.useState(1);

    const handleBiblicalList = (event) => {
        setBiblicalList(event.target.value);
        props.handleBiblicalList(event.target.value);
    };
    return (   
            <div className="form-row">
                <div className="col-auto my-1">
                <FormControl className={classes.formControl}>
                        <InputLabel id="select-levelList-Label">Select Study list</InputLabel>
                        <Select labelId="select-levelList-Label"
                            id="select-levelList"
                            value={biblicalList}
                            onChange={handleBiblicalList} >
                            {
                                props.biblicalLiteraryUnits.map(data =>
                                    <MenuItem value={data.id} key={data.id}>{data.title}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
    );
}
export function RadioVersion(props) {
    const [version, setVersion] = React.useState('kjv');
    const handleChange = (event) => {
        setVersion(event.target.value);
        props.handleChangeVersion(event);
    };
    const style1 = { display: 'flex', flexDirection: 'row' };
    return (
        <div>
            <FormControl component="fieldset">
            <FormLabel component="legend">Translation version</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={version} onChange={handleChange} style={style1}>
                    <FormControlLabel value="kjv" control={<Radio />} label="King James" />
                    <FormControlLabel value="niv" control={<Radio />} label="NIV" />
                </RadioGroup>
            </FormControl>
        </div>
    );
}


export class Translation extends Component {
    static displayName = Translation.name;
    constructor(props) {
        super(props);
        this.cardChild = React.createRef()
        this.awsPathAudio = ''; ///we dont need it in this course
        this.state = {
            currentCard:0,
            ready: false,
            studylist: 1,
            biblicalLiteraryUnits: [],
            vocabularywordsList: [],
            defaultText: '',
            defaultDefinition: '',
            groupName: null,
            audioUrl: null,
            fontSize: 32,
            currentUser: sessionTools.getCookie('btu_'),
            textPropertyName: 'text',
            definitionPropertyName: 'kjv'
        };
        this.getBiblicalLiteraryUnits = this.getBiblicalLiteraryUnits.bind(this);
        this.getBiblicalLiteraryUnitsVerses = this.getBiblicalLiteraryUnitsVerses.bind(this);

        this.handleChangeStudyList = this.handleChangeStudyList.bind(this);
        this.handleChangeVersion = this.handleChangeVersion.bind(this);
    }
    async componentDidMount() {
        this.getBiblicalLiteraryUnits();
    }

    getBiblicalLiteraryUnits() {
        try {
            fetch(config.api_url + 'TranslationSets/')
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ready: true,
                            biblicalLiteraryUnits: result
                        }, () => {
                            this.getBiblicalLiteraryUnitsVerses(result[0].id);
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);
                        this.setState({
                            ready: false,
                            error
                        });
                    }
                )

        } catch (e) {
            console.info(e);
        }
    }
    getBiblicalLiteraryUnitsVerses(setId) {
        try {
            fetch(config.api_url + 'TranslationVerses/' + setId)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ready: true,
                            vocabularywordsList: result,                            
                            studylist: setId,
                            defaultText: result[0].text,
                            defaultDefinition: result[0].kjv                            
                        }, () => {
                            
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);
                        this.setState({
                            ready: false,
                            error
                        });
                    }
                )
        } catch (e) {
            console.info(e);
        }
    }

    handleChangeVersion = (event) => {        
        this.setState({
            definitionPropertyName: event.target.value           
        }, () =>{
                
        } );
    }

    handleChangeStudyList = (value) => {
        this.getBiblicalLiteraryUnitsVerses(value);
        this.onSetCurrentCard(0);    
    };
    onSetCurrentCard(index) {
        this.setState({ currentCard: index, helperText:'' });
    }
    render() {
        if (!this.state.currentUser) {
            return <Redirect to='/Sign-in' />
        }
        else {
            var subscription = this.state.currentUser.biblicalSubscription
            if (!subscription) {
                return <Redirect to='/MyAccount' />
            } else {
                return (
                    <div>
                        <Typography variant="h6" gutterBottom>
                            Translation
                    </Typography>
                        <div className="form-row">
                            <div className="col">
                                <SelectList
                                    biblicalLiteraryUnits={this.state.biblicalLiteraryUnits}
                                    handleBiblicalList={this.handleChangeStudyList.bind(this)}
                                />
                            </div>
                            <div className="col-auto my-1 float-right">
                                <RadioVersion handleChangeVersion={this.handleChangeVersion} />
                            </div>
                        </div>

                        <Card ref={this.cardChild}
                            wordsList={this.state.vocabularywordsList}
                            defaultText={this.state.defaultText}
                            defaultDefinition={this.state.defaultDefinition}
                            pathaudioFile={this.state.audioUrl}
                            audioPlayer={false}
                            vocabularySetId={this.state.vocabularySetId}
                            groupName={this.state.groupName}
                            fontSize={this.state.fontSize}
                            textPropertyName={this.state.textPropertyName}
                            definitionPropertyName={this.state.definitionPropertyName}
                            displayExplanationIcon={true}
                            onSetCurrentCard={this.onSetCurrentCard.bind(this)}
                            handleMyTranslationChange={this.handleMyTranslationChange}
                            translationBox={true}
                            displayShuffleButton={true}
                            explanationIconText='Translate'
                            explanationIconSubText='Show Translation'
                        />
                    </div>
                );
            }
        }
    }
}
