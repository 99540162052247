import React, { Component, useEffect } from 'react';
import { authenticationService } from '../_services/authenticationService'
import { UserService } from '../_services/userService'
import { config } from '../components/Config.custom'
import { Redirect } from 'react-router-dom';
import { Connector } from '../_api/connector';

import { Formik, Form, useFormikContext } from 'formik';
import myAccountFormModel from './myAccount/formModel';
import validationSchema from '../components/myAccount/validationSchema';
//import formInitialValues from '../components/SignUp/FormModel/formInitialValues';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputField from '../components/SignUp/FormModel/inputField'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ValidationMessage from './ValidationMessage'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SelectFieldAPI from '../components/SignUp/FormModel/SelectFieldAPI';
import SelectField from '../components/SignUp/FormModel/SelectField';
import { sessionTools } from '../_services/sessionTools';
import CasClient from "react-cas-client";

import CashNetForm from './CashNetForm'
import { creativeworksStore } from '../_services/creativeworksStore';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        //marginTop: theme.spacing(3),
        //marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
            //marginTop: theme.spacing(6),
            //marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}));



export function SubscriptionDialog() {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();
    const [items, setItems] = React.useState([]);
    const { formField: { subscription } } = myAccountFormModel;
    const [openCashNet, setOpenCashNet] = React.useState(false);
    const { values: formValues } = useFormikContext();
    const [cashNetConfig, setCashNetConfig] = React.useState([]);


    const handleClickOpen = () => {
        setOpen(true);
     };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubscribe = () => {
        setOpenCashNet(true)
    };

    const getSubscriptionData = () => {
        var bearer = 'Bearer ' + sessionTools.getCookie('bt_');
        fetch(config.api_cwo + 'Subscription?itemName=BiblicalSubscription', {
            method: 'GET',
            headers: {
                'Authorization': bearer,
                'Accept': 'application/json'
            }
        }).then(res => res.json())
            .then(
                (result) => {
                    if (result.items) {
                        var items = JSON.parse(result.items);
                        var resultArray = items.filter(function (user) {
                            return user.Student === 1;
                        }).map(function (elm) {
                            return { value: elm['Months'], label: elm['Name'] + ' - $' + elm['Price'] };
                        });
                        setItems(resultArray);
                    }
                },
                (error) => {
                    console.log(error)
                    return [];
                }
            ).catch(error => { console.log(error); return []; });
    }

    useEffect(() => {
        getSubscriptionData();
        creativeworksStore.getCashNetConfiguration(true, setCashNetConfig, setOpenCashNet)
    }, []);
    const handleChangeSubscription = () => { }



    return (
        <div>
            {(openCashNet) ? (
                <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                        Opening payment platform
                    </Typography>
                    <Typography variant="subtitle1">
                        <CashNetForm formValues={formValues} casUser={true} setOpenCashNet={setOpenCashNet} cashNetConfiguration={cashNetConfig} />
                    </Typography>
                </React.Fragment>
            ) : (
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleClickOpen}>
                        Purchase Subscription
                    </Button>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">BYU Student Subscription</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Biblical Hebrew is available through two subscription plans: $30 for six months or $50 for one year.
                                Click on the select list below to subscribe for your subscription.
                            </DialogContentText>
                            <SelectField
                                name={subscription.name}
                                label={subscription.label}
                                data={items}
                                fullWidth
                                className={classes.subslabel}
                                disable={true}
                                myChange={handleChangeSubscription}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleSubscribe} color="primary">
                                Purchase
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </div>
    );
}


function getFormInitialValues(props) {
    const {
        formField: {
            username,
            email,
            password,
            passwordRepeat,
            firstName,
            lastName,
            address1,
            address2,
            city,
            zipcode,
            country,
            state,
            subscriptiontype,
            expirationdate,
            guid
        }
    } = myAccountFormModel;
    return {
        [username.name]: props.formField.username,
        [email.name]: props.formField.email,
        [password.name]: props.formField.password,
        [passwordRepeat.name]: props.formField.passwordRepeat,
        [firstName.name]: props.formField.firstName,
        [lastName.name]: props.formField.lastName,
        [address1.name]: props.formField.address1,
        [address2.name]: props.formField.address2,
        [city.name]: props.formField.city,
        [zipcode.name]: props.formField.zipcode,
        [country.name]: props.formField.country,
        [state.name]: props.formField.state,
        [subscriptiontype.name]: props.formField.subscriptiontype,
        [expirationdate.name]: props.formField.expirationdate,
        [guid.name]: props.formField.guid
    };
}



function SubscribeOption(props) {
    const isExpired = props.expired;
    if (isExpired) {

        props.setMessage('Your subscription has expired. To keep using it please repurchase subscription.');
        props.setAlertMessage(true);
        props.setSeverety("warning");

        return (<div><Typography variant="h6" component="p">
            Repurchase Subscription
        </Typography>
            <SubscriptionDialog />
        </div>);
    }
    return null;
}


export function MyAccountForm(props) {
    const classes = useStyles();
    const currentValidationSchema = validationSchema[0];
    const [saving, setSaving] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severety, setSeverety] = React.useState('');
    const [subscriptionExpired, setsubscriptionExpired] = React.useState('');
    const [states, setStates] = React.useState('false');

    const { formId, formField: {
        email,
        username,
        password,
        passwordRepeat,
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        country,
        zipcode,
        subscriptiontype,
        expirationdate,
        byucas
    } } = myAccountFormModel;

    useEffect(() => {
        try {
            UserService.isSubscriptionExpired(setsubscriptionExpired);
        }
        catch (e) {
            console.log(e)
        }
    }, []);
    const handleNext = () => {

    };

    const showMessage = (result) => {
        if (result) {
            setMessage("Your profile has been updated.");
            setSeverety("success")
            setAlertMessage(true);
        } else {
            setMessage("Oops, something went wrong. Please, try again.");
            setSeverety("warning")
            setAlertMessage(true);
        }
        setSaving(false);
    }

    const _handleSubmit = (values, actions) => {
        setSaving(true);
        UserService.updateAccount(values, showMessage);
        actions.setTouched({});
        actions.setSubmitting(false);

    }

    const handleChangeCountry = (value) => {
        if (value === 1) setStates(true);
        else setStates(false);
    };
    const handleChangeState = (value) => {
    };


    if (props.loading) {
        return (
            <React.Fragment>
                <Backdrop className={classes.backdrop} open={props.loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <CssBaseline />
            </React.Fragment>
        );
    } else {
        return (
            <div>
                <ValidationMessage open={alertMessage}
                    setshowWarningWrong={setAlertMessage}
                    message={message}
                    severity={severety} />
                <Backdrop className={classes.backdrop} open={saving} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <CssBaseline />
                <AppBar position="absolute" color="default" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" noWrap>
                            My account
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Paper className={classes.paper}>
                    <Formik
                        initialValues={getFormInitialValues(props.formInitialValues)}
                        validationSchema={currentValidationSchema}
                        onSubmit={_handleSubmit} >
                        {({ isSubmitting }) => (
                            <React.Fragment>
                                <Form id={formId} >
                                    <Typography variant="h6" gutterBottom>
                                        User information
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <InputField name={username.name} label={username.label} variant="filled"
                                                InputProps={{ readOnly: true }}
                                                fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputField name={email.name} label={email.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputField name={firstName.name} label={firstName.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputField name={lastName.name} label={lastName.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputField name={subscriptiontype.name} label={subscriptiontype.label} fullWidth variant="filled"
                                                InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputField name={expirationdate.name} label={expirationdate.label} fullWidth variant="filled"
                                                InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SubscribeOption expired={subscriptionExpired} setAlertMessage={setAlertMessage}
                                                setMessage={setMessage} setSeverety={setSeverety} />
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" className={classes.divider} />
                                    <Typography variant="h6" gutterBottom>
                                        Contact Information
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} >
                                            <InputField name={address1.name} label={address1.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <InputField name={address2.name} label={address2.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputField name={city.name} label={city.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectFieldAPI
                                                name={state.name}
                                                label={state.label}
                                                fullWidth
                                                myChange={handleChangeState}
                                                disabled={states}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectFieldAPI
                                                name={country.name}
                                                label={country.label}
                                                fullWidth
                                                myChange={handleChangeCountry}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputField name={zipcode.name} label={zipcode.label} fullWidth />
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" className={classes.divider} />
                                    <Typography variant="h6" gutterBottom>
                                        Change password
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <InputField name={password.name} label={password.label} fullWidth type="password" clearonblur="true" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputField name={passwordRepeat.name} label={passwordRepeat.label} fullWidth type="password" />
                                        </Grid>
                                        <input type="hidden" name="casuser" value={byucas} />
                                    </Grid>
                                    <React.Fragment>
                                        <div className={classes.buttons}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                className={classes.button}>
                                                Save
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                </Form>
                            </React.Fragment>
                        )}
                    </Formik>
                </Paper>
            </div>
        );
    }


}

export class SubscribeStudent extends Component {
    static displayName = SubscribeStudent.name;

    constructor(props) {
        super(props);
        this.state =
        {
            authenticate: authenticationService.isAuthenticate(), //by  default
            loading: true,
            formField: myAccountFormModel,
            currentUser: null,
            successfulCAS: false
        };
        this.getData = this.getData.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount() {
        if (this.state.authenticate)
            this.getData();
    }

    getData() {
        try {
            var loggedUser = sessionTools.getCookie('btu_');
            var bearer = 'Bearer ' + loggedUser.token;
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': bearer,
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            };
            fetch(config.api_cwo + `User/${loggedUser.guid}`, requestOptions)
                .then(res => (res.json()))
                .then(res => {
                    //console.log(res)
                    if (res.result === "0") {
                        const { currentUser } = res;
                        //console.log(currentUser);
                        const { formField } = this.state;
                        formField['username'] = currentUser.userName;
                        formField['email'] = currentUser.email;
                        formField['firstName'] = currentUser.firstName;
                        formField['lastName'] = currentUser.lastName;
                        formField['address1'] = currentUser.address1;
                        formField['address2'] = currentUser.address2;
                        formField['city'] = currentUser.city;
                        formField['zipcode'] = currentUser.postalCode;
                        formField['password'] = '';
                        formField['passwordRepeat'] = '';
                        formField['state'] = currentUser.stateName;
                        formField['country'] = currentUser.country;
                        formField['subscriptiontype'] = res.subscriptiontype;
                        formField['expirationdate'] = res.expirationdate;
                        formField['guid'] = currentUser.guid;
                        formField['casUser'] = true;
                        this.setState({ formField }, () => {
                            this.setState({ loading: false });
                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                    }
                })
                .catch(function (error) {
                    ///if status code 401. Need help here
                    console.log(error)
                });
        } catch (e) {
            this.setState({ loading: false });
        }
    }

    handleClose = (value) => {
        this.setState({ alertMessage: value });
    }



    render() {
        let casEndpoint = 'cas.byu.edu';
        let casOptions = {
            'protocol': 'https'
        };
        let gateway = false;
        let successfulCAS = this.state;

        let casClient = new CasClient(casEndpoint, casOptions);
        casClient
            .auth(gateway)
            .then(successRes => {
                successfulCAS = true;

            })
            .catch(errorRes => {
                console.error(errorRes);
            })
        if (successfulCAS) {
            Connector.resetToken();
            if (!this.state.authenticate) {
                return <Redirect to='/Sign-in' />
            } else {
                return (
                    <div className="container">
                        <div className="row m-3 rounded border shadow">
                            <MyAccountForm formInitialValues={this.state} loading={this.state.loading} />
                        </div>
                    </div>
                );

            }
        }
    }
}
